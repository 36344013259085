
import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';

import {Link } from "react-router-dom";

export default function About ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSection'>

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2'>
                        <div className='aboutSection_sub2_1'>
                            <div className='aboutSection_sub2_1_img1'> </div>
                        </div>
                        <div className='aboutSection_sub2_2'>

                                <h2>A leading international bank you can trust.</h2>

            
                                <p style={{ color: "#333", }}>
                                We got our start in USA, but we’ve been serving customers across the globe since 1988. It’s our mission to help you prosper. So we treat you and your money the way we all want to be treated—with respect. How do we do it? With simple ways to spend, save, and manage your money better. From teaching your high schooler how to set up and use a checking account to helping you buy your first home, we’ll be there, right by your side. Because, at Fortune Trustcorp Bank, we believe respecting you and your money is the first step on the journey to prosperity.



                                </p>

                                <p style={{ color: "#333", }}>
                                We take pride in everything we do. That’s why we aim to exceed your expectations and return outstanding value through our wide range of products and services.


                                </p>

                                <p style={{ color: "#333", }}>
                                A better world is important to us too. That’s why we work closely with not-for-profits and community organisations and support their initiatives through fundraising, donations and volunteering.


                                </p>

                                <h5> <b>  Our Mission: </b> </h5>
                                <p style={{ color: "#333", }}>
                                We are focused on building and sustaining long-term generational relationships with our customers
                                </p>



                                <h5> <b>Our Vision: </b> </h5>

                                <p style={{ color: "#333", }}>
                                Arbitrage Credit Union will serve all over the world and becomes the most popular Bank in this universe.


                                </p>
                                <h5><b>Our Goal:</b></h5>
                                <p style={{ color: "#333", }}>
                                Arbitrage Credit Union will serve their customers from all over the world and becomes the popular bank in this universe.


                                </p>

                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>




                    <section>

                        <div  className='aboutSection1'>
                            <div className='aboutSection1Sub'>
                                <div className='aboutSection1SubCard'>
                                   
                                    <div className='aboutSection1SubCardImg'>
                                        <div className='aboutSection1SubCardImg1'></div>

                                        <div className='aboutSection1SubCardImg2'>
                                            <h2>24/7 Support</h2>
                                            <p>
                                            We provide 24/7 customer support through e-mail and live chat.
                                            </p>
                                        </div>

                                        <p className="clearx"></p>
                                    </div>

                                        
                                </div>
                                <div className='aboutSection1SubCard'>

                                    <div className='aboutSection1SubCardImg'>
                                        <div className='aboutSection1SubCardImg11'></div>

                                        <div className='aboutSection1SubCardImg2'>
                                            <h2>Instant Transfer</h2>
                                            <p>
                                            
                                            All transfer requests are treated spontaneously once requested.

                                            </p>
                                        </div>

                                        <p className="clearx"></p>
                                    </div>

                                </div>
                                <div className='aboutSection1SubCard'>

                                    <div className='aboutSection1SubCardImg'>
                                        <div className='aboutSection1SubCardImg111'></div>

                                        <div className='aboutSection1SubCardImg2'>
                                            <h2>Fund Security</h2>
                                            <p>
                                            
                                            Bank with peace of mind, as we globally regulated and licensed bank.
                                            </p>
                                        </div>

                                        <p className="clearx"></p>
                                    </div>

                                </div>
                            </div>

                        </div>



                    </section>




                    <section>
                <div className='whyChooseUsSection'>
                    <div className='whyChooseUsSectionContainer'>

                            <div className='whyChooseUsSectionContainerHeading'>
                                <h2>Reasons Why We Are The Best.</h2>
                                <p>
                                
                                We set standards for sustainable business practices that; unleash the talents of our employees, deliver superior value to our customers and provide innovative solutions for the markets and communities we serve.

                                </p>
                            </div>


                            <div className='whyChooseUsSectionContainerDetails'>

                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Secure Checking</h2>
                                        <p>
                                        Simply the safest and smartest way to bank.
                                        </p>
                                    </div>
                                    
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Trust Services</h2>
                                        <p>
                                        Arbitrage Credit Union offers a wealth of financial resources, click below to find out more.
                                        </p>
                                    </div>
                                    

                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>

                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Mobile Banking</h2>
                                        <p>
                                        Banking at your fingertips, anytime, anywhere!
                                        </p>
                                    </div>
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Business Banking</h2>
                                        <p>
                                        Your next opportunity begins at Arbitrage Credit Union.
                                        </p>
                                    </div>

                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Mortgage</h2>
                                        <p>
                                        When it comes to home financing, you’ll find the best options at Arbitrage Credit Union.
                                        
                                        </p>
                                    </div>
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>24/7 Support</h2>
                                        <p>
                                        We provide 24/7 customer support through e-mail and live chat. Our support representatives are periodically available to elucidate any difficulties.
                                        </p>
                                    </div>

                                </div>

                                <p className="clearx"></p>
                            </div>



                    </div>


                </div>

        </section>
        






        {/* our team */}


        <section>
            <div className='ourTeamContainer'>

                <div className='ourTeamContainerSub'>
                    <h2>Our Teams</h2>

                        <div className='ourTeamContainerSubCard'>

                            <center>
                                <div className='founderImg'>
                                    <img src="team/t1.png" alt="ceo" width={80} height={80} style={{ borderRadius:"50%", }} />
                                </div>
                                <p>Phil Sutliff
                                    <br/>
                                    <span style={{ fontSize:10, color: "#333", }}> Head of Business Banking </span>
                                </p>
                                
                            </center>

                        </div>

                        <div className='ourTeamContainerSubCard'>

                            <center>
                                <div className='founderImg'>
                                <img src="team/t2.jpeg" alt="ceo" width={80} height={80} style={{ borderRadius:"50%", }} />
                                </div>
                                <p>Jennifer Rodriguez

                                    <br/>
                                    <span style={{ fontSize:10, color: "#333", }}> Cash Management Manager
 </span>
                                </p>
                            </center>

                        </div>
                        <div className='ourTeamContainerSubCard'>
                            
                            <center>
                                <div className='founderImg'>
                                <img src="team/t3.jpeg" alt="ceo" width={80} height={80} style={{ borderRadius:"50%", }} />
                                </div>
                                <p>Kevin Martin


<br/>
<span style={{ fontSize:10, color: "#333", }}> Senior Relationship Manager

</span>
</p>
                            </center>


                        </div>
                        <div className='ourTeamContainerSubCard'>

                            
                            <center>
                                <div className='founderImg'>
                                <img src="team/4.jpeg" alt="ceo" width={80} height={80} style={{ borderRadius:"50%", }} />
                                </div>
                                <p>Susan Gluszynski



<br/>
<span style={{ fontSize:10, color: "#333", }}> Community Banking Officer


</span>
</p>
                            </center>


                        </div>



                    <p className="clearx"></p>
                </div>


            </div>


        </section>




{/* faq section */}
<section>
                <div className='faqContainer'>
                    <div className='faqContainerSub'>
                        <h2>Frequently Asked Questions</h2>
                        <p style={{ color: "#333", }}>
                            We have answered some of your Questions regarding our platform. If you still need help, Please contact us.
                        </p>



                        <div className='faqContainerSubForm'>

                            {/* start */}
            

                                <div class="accordion" id="accordionExample" >



                                    {/* start */}
                                    <div className="accordion-item" >
                                        <h2 className="accordion-header" id="headingOne" >
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"  style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        Is opening an account is free?
                                        </button>
                                        </h2>

                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div className="accordion-body">
                                        
                                        Yes, we don&#039;t take any fees for opening an account.

                                       </div>
                                        </div>

                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        Is it possible to send money from Arbitrage Credit Union to another bank?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        Yes, you can send money from Arbitrage Credit Union to another bank.
                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo1">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                            How to open an account?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo1" class="accordion-collapse collapse" aria-labelledby="headingTwo1" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        Get the registration form by clicking on the Sing Up button on the top bar. Provide all information and click on the Sign Up button.
                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo2">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        How do i reset my password?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo2" class="accordion-collapse collapse" aria-labelledby="headingTwo2" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        If you have lost your pasword or you want to change it to a new one. Please click on the forgot password option and follow the steps to retrieve your password.
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo3">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        How to take a loan?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo3" class="accordion-collapse collapse" aria-labelledby="headingTwo3" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#092980"}}>
                                        <div class="accordion-body">
                                        We have several loan plans. Choose the best plan suitable for you and just click on the Apply Now button and put the amount.

                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo4">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo4" aria-expanded="false" aria-controls="collapseTwo4" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                       What do I do if I lose my card or it gets stolen?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo4" class="accordion-collapse collapse" aria-labelledby="headingTwo4" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        If your card is missing, let us know immediately. We’ll block your card
                                            right away send over a new one on the same day.To report a lost or
                                            stolen card, contact us!
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo5">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo5" aria-expanded="false" aria-controls="collapseTwo5" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        Is Storm Trust Finance digital banking secure?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo5" class="accordion-collapse collapse" aria-labelledby="headingTwo5" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        Yes. 100% Security. We measure up to all the reliable security operations.
                                        </div>
                                        </div>
                                    </div>


                                    
                                </div>

                            {/* end */}
                        </div>


                    </div>


                </div>   
            </section>
            



            <div className='dfffcc'></div>
                









                <Foot />
            
            
            
            
             </>

    );


}