// DashPaymentPage


// import $ from "jquery";
// import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";



import {useState, useEffect} from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";

import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';





// import dash images
import payimg from "../DashImg/payimg.svg";


export default function DashPaymentPage(){
    const navigateRoutes = useNavigate();

    const [amount, setAmount] = useState("");
    const [method, setMethod] = useState("");


    useEffect(() => {
        const depositMethod = Cookies.get('depositMethod');
        const depositAmount = Cookies.get('depositAmount');


        if(depositMethod && depositAmount){

            setAmount(depositAmount);
            setMethod(depositMethod);

        }else{
            
            navigateRoutes('/login');
        }
       


    }, []);





    const clearDepositCookieLogs = (e) => {
        e.preventDefault();


        Cookies.remove("depositMethod");
        Cookies.remove("depositAmount");
        
        navigateRoutes('/dashboard');

    
    }





    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub" >

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content" >

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                   




                                        {/* box 2 */}

                                        
                                        <div className="depositMainContainer">
                                            <div className="depositMainContainer_Sub" style={{height:"auto"}}>
                                                    <p className="secPara">Secured payment <i class="bi bi-key" style={{color: "#F0B90B"}}></i></p>
                                                    <h2 style={{ 
                                                        fontWeight: "bold",
                                                        color: "white",
                                                     }}>Proceed To Make Payment</h2>
                                                                                         

                                                    {/* <div className="depositAmountContainerp">
                                                        <div className="depositAmountContainer_1p">
                                                            <p>Investment Plan: </p>
                                                        </div>
                                                        <div className="depositAmountContainer_2p">
                                                        <p className="depositAmountContainer_2pp">Starter Plan</p>
                                                        </div>
                                                    </div>
                                                    <br />  */}


                                                    <div className="depositAmountContainerp">
                                                        <div className="depositAmountContainer_1p">
                                                            <p>Amount: </p>
                                                        </div>
                                                        <div className="depositAmountContainer_2p">
                                                        <p className="depositAmountContainer_2pp">${amount}</p>
                                                        </div>
                                                    </div>
                                                    <br /> 

                                                    <div className="depositAmountContainerp">
                                                        <div className="depositAmountContainer_1p">
                                                            <p>Payment Method: </p>
                                                        </div>
                                                        <div className="depositAmountContainer_2p">
                                                        <p className="depositAmountContainer_2pp">{method}</p>
                                                        </div>
                                                    </div>
                                                    <br /> 
                                                    
                                                    <div className="depositAmountContainerp">
                                                        <div className="depositAmountContainer_1p">
                                                            <p>Status: </p>
                                                        </div>
                                                        <div className="depositAmountContainer_2p">
                                                        <p className="depositAmountContainer_2pp">Pending</p>
                                                        </div>
                                                    </div>
                                                    <br /> 
                                                    

                                                {/* <h5 style={{ 
                                                    color: "white",
                                                    fontWeight: "bold",
                                                 }}>Bitcoin Wallet Address</h5> */}
                                                <p className="depositMainContainer_Sub_Para_P" style={{ color: "green", fontWeight: "bold", }}>
                                                    Contact the customer live chat support for the company official wallet address.
                                                </p>
                                                {/* submit button */}

                                                {/* <button className="depositProceedButton">Copy Wallet Address </button> */}
                                                <div className="confirmProceedButtonContainer">
                                                                    {/* <button className="confirmProceedButton"> COPY WALLET ADDRESS  </button>  */}

                                                                

                                                                    <button style={{ width: "100%" }} className="confirmProceedButton" onClick={clearDepositCookieLogs}> DONE </button>
                                                 </div>
                                                               
                                           
                                                
                                                    
                                            </div>

                                              
                                        </div>


















                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                    <img src={payimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}