// DashProfile




import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// import dash images
import proimg from "../DashImg/proimg.svg";
import proicon from "../DashImg/proicon.svg";

export default function DashProfile(){

    const navigateRoutes = useNavigate();
    const [errMsg, setErrMsg] = useState("");


    const [username, setusername] = useState("");
    const [firstname, setfirstname] = useState("");
    const [lastname, setlastname] = useState("");
    const [email, setemail] = useState("");
    const [gender, setgender] = useState("");
    const [country, setcountry] = useState("");
    const [state, setstate] = useState("");
    const [city, setcity] = useState("");
    const [mobilenum, setmobilenum] = useState("");
    const [dob, setdob] = useState("");
    const [houseAddrr, sethouseAddrr] = useState("");





    useEffect(() => {


// starts here 
const checkData1 = localStorage.getItem('tokenID');
const checkData2 = localStorage.getItem('currentUserName');

if(checkData1 && checkData2){
    let urls = "http://127.0.0.1:8000/api/viewuserprofileapipost";

    async function proceedToSendDataToServer (){
        try{
            const registerPostFeedBack = await axios.post(urls,{

                tokenCode: checkData1,
                userName: checkData2,



            }).then((res) => {

                let feedbackMsg = res.data.msg;
                let feedbackStatusCode = res.data.status;
                

                if(feedbackStatusCode == 200){

                    let usernameFromDB = res.data.usernameFromDB;
                    let firstnameFromDB = res.data.firstnameFromDB;
                    let lastnameFromDB = res.data.lastnameFromDB;
                    let emailFromDB = res.data.emailFromDB;
                    let genderFromDB = res.data.genderFromDB;
                    let countryFromDB = res.data.countryFromDB;
                    let stateFromDB = res.data.stateFromDB;
                    let cityFromDB = res.data.cityFromDB;
                    let mobilenumFromDB = res.data.mobilenumFromDB;
                    let dobFromDB = res.data.dobFromDB;
                    let houseAddrrFromDB = res.data.houseAddrrFromDB;


                    setusername(usernameFromDB);
                    setfirstname(firstnameFromDB);
                    setlastname(lastnameFromDB);
                    setemail(emailFromDB);
                    setgender(genderFromDB);
                    setcountry(countryFromDB);
                    setstate(stateFromDB);
                    setcity(cityFromDB);
                    setmobilenum(mobilenumFromDB);
                    setdob(dobFromDB);
                    sethouseAddrr(houseAddrrFromDB);



                    // toast.success(feedbackMsg);
                    

                  
                }

                if(feedbackStatusCode == 402){
                    setErrMsg(feedbackMsg);
                    $("#errMsg").show();
                    $("#depositProceedButton").show();
                    $("#processingButton").hide();
                }

                if(feedbackStatusCode == 401){
                    navigateRoutes('/login', { replace: true });
                }

                if(feedbackStatusCode == 501){
                    navigateRoutes('/login', { replace: true });
                }

                

            });

       }catch (err){

        toast.error("Error connecting to the server.");
        setErrMsg("Error connecting to the server.");
        $("#depositProceedButton").show();
        $("#processingButton").hide();
 
        //  console.log(err);

     }


    }
    proceedToSendDataToServer();


}else{

navigateRoutes('/login', { replace: true });
}


 
     }, []);
 
 
     


    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />



        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub" >
               <ToastContainer />  
 
                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content" >

                                    {/* dash view content */}
                            <div className="mainContent_1" >
                                <div className="mainContent_1_Container" >
                                    
                                        {/* box 1 */}
                                    <div className="mainContent_1_Container_box_1" >
                                            <div className="mainContent_1_Container_box_1_Content">
                                                
                                                <div className="profileImageContainer">

                                                    <div className="profileimage_Sub">
                                                    <img src={proicon} style={{width: "100%", height: "100%" }}  />
                                                    </div>

                                                    {/* <p className="profileimage_Sub_Para">
                                                        Click here to upload a profile picture
                                                    </p> */}

                                                    <p style={{ fontSize:14,padding:5, }}>
                                                        {firstname} {lastname}
                                                    </p>

                                                </div>


                                            </div>

                                        </div>



                                        {/* box 2 */}

                                                                                
                                        <div className="depositMainContainer" style={{ height: "auto" }}>
                                            <div className="depositMainContainer_Sub" style={{ height: "auto" }}> 
                                                    <h4>Profile Details</h4>
                                            <form>
                                         
                                                    <label className='depositAmountContainer_2_Labelzs'> Username </label>
                                                     <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Username" value={username}  readOnly  className='DepositformInputAmount'  />
                                                        </div>
                                                    </div>
                                                    <br /> 

                                                    <label className='depositAmountContainer_2_Labelzs'> First Name</label>
                                                    <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Full Name" value={firstname}  readOnly   className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                    <br /> 

                                                    <label className='depositAmountContainer_2_Labelzs'> Last Name</label>
                                                    <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Full Name"  value={lastname}  readOnly  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                    <br /> 

                                                   
                                                    <label className='depositAmountContainer_2_Labelzs'> Email </label>
                                                    <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Email" value={email}  readOnly  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                    <br /> 


                                                    <label className='depositAmountContainer_2_Labelzs'> Gender </label>
                                                    <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Male" value={gender}  readOnly  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                    <br /> 

                                                    <label className='depositAmountContainer_2_Labelzs'> Country </label>
                                                    <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Male" value={country}  readOnly  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                    <br /> 

                                                    <label className='depositAmountContainer_2_Labelzs'> State </label>
                                                    <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Male" value={state}  readOnly  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                    <br /> 

                                                    <label className='depositAmountContainer_2_Labelzs'> City </label>
                                                    <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Male" value={city}  readOnly  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                    <br /> 

                                                    <label className='depositAmountContainer_2_Labelzs'> Mobile Number</label>
                                                    <div className="depositAmountContainer">     
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Mobile Number" value={mobilenum}  readOnly  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                    <br /> 

                                                    <label className='depositAmountContainer_2_Labelzs'> Date Of Birth</label>
                                                    <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Phone Number" value={dob}  readOnly  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                    <br /> 
                                               
                                                    <label className='depositAmountContainer_2_Labelzs'>House Address</label>
                                                    <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="House Address" value={houseAddrr}  readOnly  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                    <br /> 
                                               

                                                   

                                                {/* submit button */}

                                                {/* <button className="depositProceedButton">Update Profile </button> */}

                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>
























                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={proimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}