// DiscretionaryPort






import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function DiscretionaryPort ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                   <li> <Link to='/privatebanking'> Private Banking </Link> </li>
                                   <li> <Link to='/creditcardservices'> Credit Card Services </Link> </li>
                                    {/* <li> <Link to='/discretionaryport'> Discretionary Portfolios </Link> </li> */}
                                    <li> <Link to='/investorvisaport'> Investor Visa Portfolios </Link> </li>
                                    <li> <Link to='/executiononlyport'> Execution Only Portfolios </Link> </li>
                                    
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Discretionary Portfolios </h2>

            
                                <p style={{ color: "#333", }}>
                                Our range of bespoke discretionary portfolios will allow you to benefit from a specialist investment solution focused on your investment goals, risk appetite and financial circumstances.





                                </p>
                                <p style={{ color: "#333", }}>
                                Is this service right for you?



                                </p>


                                <p style={{ color: "#333", }}>
                                Yes, if you are a high net worth individual looking to diversify your wealth and gain a higher return on your investments.





                                </p>
                               


                                <h2> Why choose our Discretionary portfolios? </h2>

                                <p style={{ color: "#333", }}>
                                    1. You will have your own dedicated Private Banker that will guide you and help you with all your needs.

                                </p>
                                <p style={{ color: "#333", }}>
                                    2. Your portfolio will be tailored made to suit your risk and investment needs and managed by an experienced professional.


                                </p>
                                <p style={{ color: "#333", }}>
                                    3. You can diversify your wealth into a global portfolio denominated in either EUR, GBP or USD.

                                </p>
                                <p style={{ color: "#333", }}>
                                    4. Your investment will be protected under USA regulations (FCA & PRA).

                                </p>
                                
                                {/* <p style={{ color: "#333", }}>
                                    5. You will be offered comprehensive travel insurance for you and your family.

                                </p>
                                <p style={{ color: "#333", }}>
                                    6. You will be offered extended warranty, purchase and refund protection as well as emergency credit card replacement service.

                                </p>
                                <p style={{ color: "#333", }}>
                                    7. Your family and friends can benefit from your membership by becoming supplementary credit card holders.

                                </p>

                                <p style={{ color: "#333", }}>
                                    8. You can manage your account any time, any day online.

                                </p>
                                <p style={{ color: "#333", }}>
                                    9. There is no pre-set spending limit and no interest charges if the balance is settled in accordance with credit card conditions.

                                </p> */}

                                

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}