// InternationTransfer




import $ from "jquery";
import {Link } from "react-router-dom";


import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";
import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';



// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";


// import dash images
import withimg from "../DashImg/withimg.svg";

export default function InternationTransfer(){


    const [receiverAcctName, setReceiverAcctName] = useState("");
    const [receiverAcctNumber, setReceiverAcctNumber] = useState("");
    const [receiverBankName, setReceiverBankName] = useState("");
    const [receiverRoutingNumber, setReceiverRoutingNumber] = useState("");
    const [receiverSwiftcode, setReceiverSwiftcode] = useState("");
    const [sendingAmount, setSendingAmount] = useState("");
    const [sendingPurpose, setSendingPurpose] = useState("");

    const [errMsg, setErrMsg] = useState("");
    const [errTransferMsg, setErrTransferMsg] = useState("");

    const navigateRoutes = useNavigate();
    


    const hideModalContainerMsg = () => {
        $("#showModalContainerMsg").hide();
    }






    const confirmRequest = (e) => {

        e.preventDefault();
        $("#depositProceedButton").hide();
        $("#processingButton").show();

        const acctBalFromDBChecking = localStorage.getItem('acctbalaa');
        const acctBalFromDBInt = parseInt(acctBalFromDBChecking, 10);

        if(!acctBalFromDBChecking){
            navigateRoutes('/login', { replace: true });
        }

        if(receiverAcctName == "" || receiverAcctNumber == "" || receiverBankName == "" || receiverRoutingNumber == "" || receiverSwiftcode == "" || sendingAmount == "" || sendingPurpose == ""){

            toast.error("Provide all details");
            setErrMsg("Provide all details");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if(isNaN(receiverAcctNumber)){

            toast.error("Enter a valid account number");
            setErrMsg("Enter a valid account number");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if(receiverAcctNumber.length > 18){

            toast.error("Enter a valid account number");
            setErrMsg("Enter a valid account number");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if(isNaN(sendingAmount)){

            toast.error("Enter a valid amount");
            setErrMsg("Enter a valid amount");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if(sendingAmount > acctBalFromDBInt){

            toast.error("Insufficient Funds");
            setErrMsg("Insufficient Funds");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;


        }else{



            // starts here 
            const checkData1 = localStorage.getItem('tokenID');
            const checkData2 = localStorage.getItem('currentUserName');



           if(checkData1 && checkData2){

            let urls = "http://127.0.0.1:8000/api/confirmiftransfercangothrough";

            async function proceedToSendDataToServer (){

                try{
                    const registerPostFeedBack = await axios.post(urls, {

                        tokenCode: checkData1,
                        userName: checkData2,
                        sendingAmount: sendingAmount,

                    }).then((res) => {
                        
                        let feedbackMsg = res.data.msg;
                        let feedbackStatusCode = res.data.status;
                        let currentDateOfTransferx = res.data.dateOfTransfer;


                        if(feedbackStatusCode == 200){
                            toast.success(feedbackMsg);
                            

                            // set the informations in cookies
                            Cookies.set('receiverAcctName', receiverAcctName, { expires: 1 });
                            Cookies.set('receiverAcctNumber', receiverAcctNumber, { expires: 1 });
                            Cookies.set('receiverBankName', receiverBankName, { expires: 1 });
                            Cookies.set('receiverRoutingNumber', receiverRoutingNumber, { expires: 1 });
                            Cookies.set('receiverSwiftcode', receiverSwiftcode, { expires: 1 });
                            Cookies.set('sendingAmount', sendingAmount, { expires: 1 });
                            Cookies.set('sendingPurpose', sendingPurpose, { expires: 1 });
                            Cookies.set('transferType', "International", { expires: 1 });
                            Cookies.set('transferDate', currentDateOfTransferx, { expires: 1 });
        
        

                            
                            setTimeout(() => {
                                // 👇 Redirects to about page, note the `replace: true`
                                navigateRoutes('/firstsecuritycode');
                              }, 2000);

                          
                        }
       
                        if(feedbackStatusCode == 402){
                            setErrTransferMsg(feedbackMsg);
                            $("#showModalContainerMsg").show();
                            $("#depositProceedButton").show();
                            $("#processingButton").hide();
                        }

                        if(feedbackStatusCode == 401){
                            navigateRoutes('/login', { replace: true });
                        }

                        if(feedbackStatusCode == 501){
                            navigateRoutes('/login', { replace: true });
                        }
       

                    });




                }catch (err){
 
                    toast.error("Error connecting to the server.");
                    setErrMsg("Error connecting to the server.");
                    $("#depositProceedButton").show();
                    $("#processingButton").hide();
             
            //  console.log(err);
 
         }




            }


            proceedToSendDataToServer();




           }else{

            navigateRoutes('/login', { replace: true });
           }
        //    end here



           
           
        }


       



    }















    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                       

                                <ToastContainer />     


                                        {/* box 2 */}

                                                                                
                                        <div className="depositMainContainer">
                                            <div className="depositMainContainer_Sub" style={{ height: "auto" }}>

                                            <form>
                                               

                                                <h3 className="intLabel">International Transfer</h3>

                                               
                                                <p style={{ color: "red", padding:5, }}>{errMsg}</p>

                                              

                                                <label htmlFor="fullname" className='depositFormLabel'>Enter Account Number </label> <br />
                                                
                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="number" placeholder="Account Number" onChange={(e) => setReceiverAcctNumber(e.target.value)}  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>


                                                <br /> 

                                                <label htmlFor="fullname" className='depositFormLabel'>Enter Account Name </label> <br />
                                                
                                                <div className="depositAmountContainer">
                                                    {/* <div className="depositAmountContainer_1">
                                                        <span>$</span>
                                                    </div> */}
                                                    <div className="depositAmountContainer_2">
                                                    <input type="text" placeholder="Account Name"  onChange={(e) => setReceiverAcctName(e.target.value)}  className='DepositformInputAmount' />
                                                    </div>
                                                </div>


                                            <br /> 
                                            
                                                
                                                <label htmlFor="fullname" className='depositFormLabel'>Enter Bank Name </label> <br />
                                                
                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Bank Name" onChange={(e) => setReceiverBankName(e.target.value)}  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>


                                                <br /> 

                                                <label htmlFor="fullname" className='depositFormLabel'>Enter Bank Routing Number </label> <br />
                                                
                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Bank Routing Number" onChange={(e) => setReceiverRoutingNumber(e.target.value)}  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>


                                                <br /> 

                                                <label htmlFor="fullname" className='depositFormLabel'>Enter Swift | BIC Code </label> <br />
                                                
                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Swift | BIC Code" onChange={(e) => setReceiverSwiftcode(e.target.value)}  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>


                                                <br /> 
        

                                                {/* enter amount */}
                                                <label htmlFor="fullname" className='depositFormLabel'>Enter Amount  </label> <br />
                                                
                                                    <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div>
                                                        <div className="depositAmountContainer_2">
                                                        <input type="number" placeholder="100"  onChange={(e) => setSendingAmount(e.target.value)}   className='DepositformInputAmount' />
                                                        </div>
                                                    </div>


                                                    <br /> 
                                                   


                                                    <label htmlFor="fullname" className='depositFormLabel'>Enter Purpose of the Transfer </label> <br />
                                                
                                                <div className="depositAmountContainer">
                                                    {/* <div className="depositAmountContainer_1">
                                                        <span>$</span>
                                                    </div> */}
                                                    <div className="depositAmountContainer_2">
                                                    <input type="text" placeholder="Purpose of the Transfer"  onChange={(e) => setSendingPurpose(e.target.value)}  className='DepositformInputAmount' />
                                                    </div>
                                                </div>


                                            <br /> 
    


                                                {/* submit button */}

                                                {/* <button className="depositProceedButton"> <i class="bi bi-send-check"></i> Proceed  </button> */}
                                                <button className="depositProceedButton" id="depositProceedButton" onClick={confirmRequest}><i class="bi bi-send-check"></i> Proceed </button>
                                                <button id="processingButton" className="cmn-btn w-100 buttForm"  disabled> 
                                                    <div class="spinner-border" role="status" style={{ width: 18, height:18 }}>
                                                        <span class="visually-hidden" >Loading...</span>
                                                    </div>
                                                    PROCESSING...
                                                </button>
                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>

















                                    {/* start overlay modal */}
                                    <div className="modalOverlay" id="showModalContainerMsg">
                                        <div className="modalOverlayContainer" style={{  height: "auto", padding:40, }}>

                                            <center>
                                                {/* succImg */}
                                                {/* <img src={suc1} width='60' style={{paddingTop: 30}} /> */}
                                                <img src={errimg} width='70' style={{paddingTop: 30}} />

                                                <h3> </h3>

                                                {/* <h3 style={{color: "red"}}>Error </h3> */}
                                                
                                                <div className="modalPara">
                                                    <p>
                                                        {errTransferMsg}
                                                    </p>

                                                </div>
                                               


                                                <div className="modalButtons"> 

                                                    {/* <div className="confirmModalButton">
                                                        <p>
                                                            Confirm
                                                        </p>
                                                    </div> */}
                                                    <div className="cancelModalButton" onClick={hideModalContainerMsg}>
                                                        <p>
                                                            OK
                                                        </p>
                                                    </div>

                                                </div>

                                            </center>
                                        

                                        </div>
                                    </div>
                                    {/* end overlay modal */}








                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={withimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}