// DashWithdraw


import $ from "jquery";
import {Link } from "react-router-dom";



// toast notification
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";

import {useState} from 'react';
import { useNavigate } from 'react-router-dom';

// import dash images
import withimg from "../DashImg/withimg.svg";

export default function DashWithdraw(){

    const [transferTyper, setTransferType] = useState("");
    const [errMsg, setErrMsg] = useState("");

    const navigateRoutes = useNavigate();
    
    const confirmRequest = (e) => {

        e.preventDefault();
        $("#depositProceedButton").hide();
        $("#processingButton").show();


        if(transferTyper == ""){

            toast.error("Select a transfer type");
            setErrMsg("Select a transfer type");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if(transferTyper == "Select"){

            toast.error("Select a transfer type");
            setErrMsg("Select a transfer type");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;


        }else if(transferTyper == "International Transfer"){

              navigateRoutes('/internationaltransferpage');

        }else if(transferTyper == "Local Transfer"){

              navigateRoutes('/localtransfer');

        }else{

          
            $("#depositProceedButton").show();
            $("#processingButton").hide();

        }




    }










    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content" >

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container" >
                                    
                                <ToastContainer />     



                                        {/* box 2 */}

                                                                                
                                        <div className="depositMainContainer">
                                            <div className="depositMainContainer_Sub" style={{ height: "auto",  }}>

                                            <form>
                                               

                                               <p style={{ color: "red", }}>{errMsg}</p>

                                                {/* select payment method */}
                                                <label htmlFor="fullname" className='depositFormLabel'> Select Transfer Type </label> <br />
                                                <select className='DepositformInputSelect' style={{height:40}} onChange={(e) => setTransferType(e.target.value)}>
                                                    {/* <option value="select">-- Select --</option> */}
                                                    <option value="Select" selected> -- Select -- </option>
                                                    <option value="Local Transfer"> Local Transfer </option>
                                                    <option value="International Transfer"> International Transfer </option>
                                                    
                                                </select>

                                                <br /> 
                                                <br /> 

                                            

                                                {/* submit button */}

                                                <button className="depositProceedButton" id="depositProceedButton" onClick={confirmRequest}><i class="bi bi-send-check"></i> Proceed </button>
                                                <button id="processingButton" className="cmn-btn w-100 buttForm"  disabled> 
                                                    <div class="spinner-border" role="status" style={{ width: 18, height:18 }}>
                                                        <span class="visually-hidden" >Loading...</span>
                                                    </div>
                                                    PROCESSING...
                                                </button>
                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>
























                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={withimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}