// PersonalBanking



import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function PersonalBanking ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                    {/* <li> <Link to='/personalbanking'> Personal Banking </Link> </li> */}
                                    <li> <Link to='/savingsaccount'> Savings Account </Link> </li>
                                    <li> <Link to='/currentaccount'> Current Account </Link> </li>
                                    <li> <Link to='/noticeaccount'> Notice Deposit Account </Link> </li>
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Personal Banking </h2>

            
                                <p style={{ color: "#333", }}>
                                As a personal banking customer you can expect an efficient and personal service from our experienced staff. Our wide range of products and services provides excellent value as well as giving you the flexibility to manage your banking affairs in a way that suits your lifestyle.



                                </p>

                                <p style={{ color: "#333", }}>
                                We offer a choice of competitive rates, market-leading systems and top-quality service, designed to offer you more choice.



                                </p>

                                <p style={{ color: "#333", }}>
                                We pride ourselves as being a bank that welcomes customers from all locations and origins with the promise that you will be treated fairly, consistently and professionally, with individual service being an absolute priority.



                                </p>

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}