// AccountSetUpDeposit






import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";


import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";
import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// import dash images
import withimg from "../DashImg/withimg.svg";

export default function AccountSetUpDeposit(){


    const navigateRoutes = useNavigate();

    const [depositType, setDepositType] = useState("");
    const [errMsg, setErrMsg] = useState("");

    const [msgLabel, setMsgLabel] = useState("");
    const [msgPara, setMsgPara] = useState("");



    const hideModalContainerMsg = () => {
        $("#showModalContainerMsg").hide();

        navigateRoutes('/login');
    }




    const confirmRequest = (e) => {

        e.preventDefault();
        $("#depositProceedButton").hide();
        $("#processingButton").show();


        if(depositType == ""){

            toast.error("Select a deposit type");
            setErrMsg("Select a deposit type");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if(depositType == "Select"){

            toast.error("Select a deposit type");
            setErrMsg("Select a deposit type");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if(depositType == "Crypto"){

            setTimeout(() => {
                // 👇 Redirects to about page, note the `replace: true`
                navigateRoutes('/accountSetUpDepositcrypto');
              }, 1000);



        }else{

            let theMsgBe = "You are about to make a deposit via " + depositType + ", please contact the live chat support for further enquiries.";
            setMsgLabel(depositType);
            setMsgPara(theMsgBe);
            
            setTimeout(() => {
                $("#showModalContainerMsg").show();
              }, 1000);




        }
    
    
    
    
    }







    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                {/* <DashNav /> */}


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                {/* <DashHead /> */}






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content" >

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                       

                                <ToastContainer />  

                                        {/* box 2 */}

                                                                                
                                        <div className="depositMainContainer" style={{ 
                                            margin:0,
                                            position: "absolute",
                                            top: "30%",
                                            
                                         }}>
                                            <div className="depositMainContainer_Sub" style={{ 
                                               height: "auto",
                                            }}>

                                            <form>
                                               
                                                <p style={{ color: "red" }}>
                                                    {errMsg}
                                                </p>
                                               

                                                {/* select payment method */}
                                                <label htmlFor="fullname" className='depositFormLabel'> Select Deposit Type </label> <br />
                                                <select className='DepositformInputSelect' style={{height:40}} onChange={(e) => setDepositType(e.target.value)}>
                                                    {/* <option value="select">-- Select --</option> */}
                                                    <option value="Select"> -- Select -- </option>
                                                    <option value="Crypto"> Fund Account Via Cryptocurrency </option>
                                                    <option value="Wire Transfer"> Fund Account Via Wire Transfer </option>
                                                    <option value="PayPal"> Fund Account Via PayPal </option>
                                                    <option value="Western Union"> Fund Account Via Western Union </option>
                                                    
                                                </select>

                                                <br /> 
                                                <br /> 

                                            

                                                {/* submit button */}

                                                <button className="depositProceedButton" id="depositProceedButton" onClick={confirmRequest}><i class="bi bi-send-check"></i> Proceed </button>
                                                <button id="processingButton" className="cmn-btn w-100 buttForm"  disabled> 
                                                    <div class="spinner-border" role="status" style={{ width: 18, height:18 }}>
                                                        <span class="visually-hidden" >Loading...</span>
                                                    </div>
                                                    PROCESSING...
                                                </button>
                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>














 {/* start overlay modal */}
 <div className="modalOverlay" id="showModalContainerMsg">
                                        <div className="modalOverlayContainer" style={{  height: "auto", padding:40, }}>

                                            <center>
                                                {/* succImg */}
                                                {/* <img src={suc1} width='60' style={{paddingTop: 30}} /> */}
                                                <img src={errimg} width='70' style={{paddingTop: 30}} />

                                                <h3> Deposit Via  {msgLabel} </h3>

                                                {/* <h3 style={{color: "red"}}>Error </h3> */}
                                                
                                                <div className="modalPara">
                                                    <p>
                                                        {msgPara}
                                                    </p>

                                                </div>
                                               


                                                <div className="modalButtons"> 

                                                    {/* <div className="confirmModalButton">
                                                        <p>
                                                            Confirm
                                                        </p>
                                                    </div> */}
                                                    <div className="cancelModalButton" onClick={hideModalContainerMsg}>
                                                        <p>
                                                            OK
                                                        </p>
                                                    </div>

                                                </div>

                                            </center>
                                        

                                        </div>
                                    </div>
                                    {/* end overlay modal */}












                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            {/* <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={withimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div> */}





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}