

import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";


import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';


// import dash images
import dashimg1 from "../DashImg/dashimg1.svg";

import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";


export default function DashIndex(){
    const [fullName, setFullName] = useState("");
    const [acctNumber, setAcctNumber] = useState("");
    const [acctBal, setAcctBal] = useState("");
    const [acctType, setAcctType] = useState("");
    const [loanBal, setLoanBal] = useState("");
    const [ippAddr, setIppAddr] = useState("");
    const [dateJoin, setDateJoin] = useState("");
    const [pendingDep, setPendingDep] = useState("");
    const [pendingWith, setPendingWith] = useState("");
    const [totalDep, setTotalDep] = useState("");
    const [totalWith, setTotalWith] = useState("");
    
    const navigateRoutes = useNavigate();


    useEffect(() => {

        const acctbalaa = localStorage.getItem('acctbalaa');
        const loanbalaa = localStorage.getItem('loanbalaa');
        const pendingdepositaa = localStorage.getItem('pendingdepositaa');
        const pendingwithdrawalaa = localStorage.getItem('pendingwithdrawalaa');
        const totaldepositaa = localStorage.getItem('totaldepositaa');
        const totalwithdrawalaa = localStorage.getItem('totalwithdrawalaa');
        const dateofregaa = localStorage.getItem('dateofregaa');
        const accounttypexaa = localStorage.getItem('accounttypexaa');
        const ipaddrxaa = localStorage.getItem('ipaddrxaa');
        const acctnameaa = localStorage.getItem('acctnameaa');
        const acctnum = localStorage.getItem('acctnum');
        
       if(acctbalaa && loanbalaa && pendingdepositaa && pendingwithdrawalaa && totaldepositaa && totalwithdrawalaa && dateofregaa && accounttypexaa && ipaddrxaa && acctnameaa && acctnum){

        setFullName(acctnameaa);
        setAcctNumber(acctnum);
        setAcctBal(acctbalaa);
        setAcctType(accounttypexaa);
        setLoanBal(loanbalaa);
        setIppAddr(ipaddrxaa);
        setDateJoin(dateofregaa);
        setPendingDep(pendingdepositaa);
        setPendingWith(pendingwithdrawalaa);
        setTotalDep(totaldepositaa);
        setTotalWith(totalwithdrawalaa);
        
       }else{
        
        navigateRoutes('/login', { replace: true });

       }
       

    }, []);





    return (
        <>
            <div className="mainDashIndexContainer">

                {/* import dashnav here */}

                    <DashNav />


             {/* main section */}
             <section className="mainContainerSection">


                {/* import dash nav here */}
                    <DashHead />






                

            {/* main */}

                <main className="mainDashContent">



                    {/* start container holding main content */}
                   <div className="mainDashContent_Sub">

                        
                        {/* start other content enters here */}
                        <div className="mainDashContent_Sub_Content">

                                        {/* dash view content */}
                                <div className="mainContent_1">

                                    <div className="mainContent_1_Container">
                                        
                                        {/* box 1 */}
                                        <div className="mainContent_1_Container_box_1">
                                            <div className="mainContent_1_Container_box_1_Content">
                                                <h2> </h2>
                                                <h2> </h2>

                                                <p className="">${acctBal}</p>

                                                <small className="mainContent_1_Container_box_1_Content_small">Account Number: {acctNumber} </small> <br />
                                                <small className="mainContent_1_Container_box_1_Content_small">Account Name: {fullName} </small>
                                            </div>

                                        </div>



                                        {/* box 2 */}
                                        <div className="mainContent_1_Container_box_2">
                                             <div className="mainContent_1_Container_box_2_Content">
                                                <h3>Account Information</h3>


                                                {/* recent  */}
                                                <div className="mainContent_1_Container_box_2_Content_recentContainer">

                                                     {/* begins loops here */}
                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover" style={{ height: "auto" }}>
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Account Type</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para"> {acctType} </p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date"> </small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover" style={{ height: "auto" }}>
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Loan Balance</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para"> ${loanBal}</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date"> </small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover" style={{ height: "auto" }}>
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Account IP Address</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">{ippAddr}</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date"> </small>
                                                        </div>

                                                        {/* <div className="notificationDetailsCard_1_Card_Details notifyHover" style={{ height: "auto" }}>
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Last Login</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">Nil</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date"> </small>
                                                        </div> */}

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover" style={{ height: "auto" }}>
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Account Status</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">Active</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date"> </small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover" style={{ height: "auto" }}>
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label"> Date Joined </h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">{dateJoin}</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date"> </small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover" style={{ height: "auto" }}>
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label"> Total  Deposit </h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">${totalDep}</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date"> </small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover" style={{ height: "auto" }}>
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label"> Total Pending Deposit </h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">${pendingDep}</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date"> </small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover" style={{ height: "auto" }}>
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label"> Total  Withdrawal </h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">${totalWith}</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date"> </small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover" style={{ height: "auto" }}>
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label"> Total Pending Withdrawal </h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">${pendingWith}</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date"> </small>
                                                        </div>

                                                        {/* <div className="notificationDetailsCard_1_Card_Details notifyHover" style={{ height: "auto" }}>
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label"> V </h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">$0</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date"> </small>
                                                        </div> */}
                                                        {/* end loops here */}

                                                        


                                                        {/* <div className="notificationFooter">
                                                            <Link to="/dashtransaction">
                                                                 <p>view profile </p>
                                                           </Link>
                                                           
                                                        </div> */}
                                                </div>

                                             </div>

                                        </div>




                                    </div>

                                
                                </div>




                                    {/* start overlay modal */}
                                    <div className="modalOverlay">
                                        <div className="modalOverlayContainer">

                                            <center>
                                                {/* succImg */}
                                                <img src={suc1} width='60' style={{paddingTop: 30}} />
                                                {/* <img src={errimg} width='70' style={{paddingTop: 30}} /> */}

                                                <h3>Successful</h3>

                                                {/* <h3 style={{color: "red"}}>Error </h3> */}
                                                
                                                <div className="modalPara">
                                                    <p>
                                                        Your transaction of $100 was successful, please proceed to confirm.
                                                       
                                                    </p>

                                                </div>
                                               


                                                <div className="modalButtons"> 

                                                    <div className="confirmModalButton">
                                                        <p>
                                                            Confirm
                                                        </p>
                                                    </div>
                                                    <div className="cancelModalButton">
                                                        <p>
                                                            Cancel
                                                        </p>
                                                    </div>

                                                </div>

                                            </center>
                                        

                                        </div>
                                    </div>
                                    {/* end overlay modal */}





                                {/* image container */}
                                <div className="mainContent_2">
                                    <div className="mainContent_2_Container">
                                    <img src={dashimg1} style={{width: "100%", height: "50vh" }}  />
                                    </div>
                                </div>





                        </div>
                     {/* end other content enters here */}




                    </div>                             
                     {/* end container holding main content */}











                     {/* start footer is starts here */}
                        {/* <DashFoot /> */}
                    {/* end footer is starts here */}                            

                </main>




            












   
               
                
               
          
   

            </section>





               










              













            </div>
    
        </>
    );



}

