// DashNotification





// import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";



// import dash images
import notifyimg from "../DashImg/notifyimg.svg";


export default function DashNotification(){

 





    return (
        <>
            <div className="mainDashIndexContainer">

                {/* import dashnav here */}

                    <DashNav />


             {/* main section */}
             <section className="mainContainerSection">


                {/* import dash nav here */}
                    <DashHead />






                

            {/* main */}

                <main className="mainDashContent">



                    {/* start container holding main content */}
                   <div className="mainDashContent_Sub">

                        
                        {/* start other content enters here */}
                        <div className="mainDashContent_Sub_Content">

                                        {/* dash view content */}
                                <div className="mainContent_1">

                                    <div className="mainContent_1_Container">
                                        
                                        


                                        {/* box 2 */}
                                        <div className="mainContent_1_Container_box_2">
                                             <div className="mainContent_1_Container_box_2_Content" style={{height: "auto"}}>
                                                <h3>Recent Notifications</h3>


                                                {/* recent  */}
                                                <div className="mainContent_1_Container_box_2_Content_recentContainer">

                                                     {/* begins loops here */}
                                                     <div className="notificationDetailsCard_1_Card_Details notifyHover">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Deposit</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500.</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Withdrawal</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500.</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Referral Bonus</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Investment</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500.</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>
                                                        {/* end loops here */}


                                                        <div className="notificationFooter">
                                                            <Link to="/dashboard">
                                                                 <p>Next Page </p>
                                                           </Link>
                                                           
                                                        </div>
                                                </div>

                                             </div>

                                        </div>




                                    </div>

                                
                                </div>





                                {/* image container */}
                                <div className="mainContent_2">
                                    <div className="mainContent_2_Container">
                                    <img src={notifyimg} style={{width: "100%", height: "50vh" }}  />
                                    </div>
                                </div>





                        </div>
                     {/* end other content enters here */}




                    </div>                             
                     {/* end container holding main content */}











                     {/* start footer is starts here */}
                        <DashFoot />
                    {/* end footer is starts here */}                            

                </main>




            












   
               
                
               
          
   

            </section>





               










              













            </div>
    
        </>
    );



}

