// DashGroupMsg






// import $ from "jquery";
// import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";



// import dash images
import proicon from "../DashImg/proicon.svg";

export default function DashGroupMsg(){

    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1" >
                                <div className="mainContent_1_Container">
                                    
                                        {/* box 1 */}
                                        
                                        {/* box 2 */}

                                                                                
                                       {/* table div container */}
                                        <div className="transactionContainer">
                                            <div className="transactionContainer_Sub">
                                                <h2>Support Chat</h2>

                                                {/* chat container */}
                                                <div className="groupMsgMainContainer">
                                                    

                                                    {/* loop msgs here from db starts */}
                                                    <div className="msgContainerView">
                                                        <div className="msgContainerView_Img">
                                                            {/* msg image here */}
                                                            <div className="msgContainerView_Img_Img">
                                                                <img src={proicon} style={{width: "100%", height: "100%" }}  />
                                                            </div>
                                                        </div>

                                                        <div className="msgContainerView_Msg">
                                                            <div className="msgContainerView_Msg_Msg">
                                                                <p>
                                                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum
                                                                
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="msgContainerView">
                                                        <div className="msgContainerView_Img">
                                                            <div className="msgContainerView_Img_Img">
                                                            <img src={proicon} style={{width: "100%", height: "100%" }}  />
                                                            </div>
                                                            
                                                        </div>

                                                        <div className="msgContainerView_Msg">
                                                            <div className="msgContainerView_Msg_Msg">
                                                                <p>
                                                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum
                                                                
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   

                                                     {/* loop msgs here from db ends */}



                                                </div>


                                                    <br />
                                                {/* send msg container */}
                                                <div className="groupMsgSendContainer">
                                                    {/* <form> */}
                                                        <div className="groupMsgSendContainer_1">
                                                            <input type="text" className="groupMsgSendContainer_Text" placeholder="Type your message here"  />
                                                        </div>


                                                        <div className="groupMsgSendContainer_2">
                                                            <button>Send <i class="bi bi-send"></i></button>
                                                        </div>
                                                    {/* </form> */}
                                                </div>




                                            </div>
                                        </div>






















                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            {/* <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={kycimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div> */}





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}