// SplashScreen



import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";

import {useEffect} from 'react';
import axios from 'axios';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// import dash images
import dashimg1 from "../DashImg/dashimg1.svg";

import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";


export default function SplashScreen(){

    

    const navigateRoutes = useNavigate();




    useEffect(() => {

       const checkIfUserTokenIsActive = localStorage.getItem('tokenID');
       const checkIfUserIsLogin = localStorage.getItem('currentUserName');

       if(checkIfUserTokenIsActive && checkIfUserIsLogin){
        // check if the detaills received are in legit

       
        let urls = "http://127.0.0.1:8000/api/confirmclcookieinfo";

        async function confirmClientCookieLoginInfiormation (){
            
            try{

                const checkValidUserDetails = await axios.post(urls,{
                    checkIfUserTokenIsActive: checkIfUserTokenIsActive,
                    checkIfUserIsLogin: checkIfUserIsLogin,

                }).then((res) => {
                    let feedbackStatusCode = res.data.status;

                    if(feedbackStatusCode == 200){

                         Cookies.set('isUserOnline', 'yah', { expires: 1 });

                        //  navigateRoutes('/dashboard', { replace: true });

                        setTimeout(() => {
                            // 👇 Redirects to about page, note the `replace: true`
                            navigateRoutes('/dashboard', { replace: true });
                          }, 3000);

                     }
                    


                    if(feedbackStatusCode == 401){
                       navigateRoutes('/login', { replace: true });
                    }

                });


            }catch (err){



            }

        }

        confirmClientCookieLoginInfiormation();



           

            // console.log("islogin");
       }else{
            navigateRoutes('/login', { replace: true });
       }


    }, []);


    





    return (
        <>
            <div className="mainDashIndexContainer">

                {/* import dashnav here */}

                   
                   


             {/* main section */}
             <section className="mainContainerSection">


                {/* import dash nav here */}
                   






                

            {/* main */}

                <main className="mainDashContent">



                    {/* start container holding main content */}
                   <div className="mainDashContent_Sub">

                        
                        {/* start other content enters here */}
                        <div className="mainDashContent_Sub_Content">

                                        {/* dash view content */}
                                <div className="mainContent_1">

                                    <div className="mainContent_1_Container">
                                        








                                        {/* box 1 */}
                                        <div className="mainContent_1_Container_box_1" style={{ 
                                            margin:0,
                                            position: "absolute",
                                            top: "40%",
                                         }}>
                                            <div className="mainContent_1_Container_box_1_Content">
                                               
                                                <h2>   Welcome To Arbitrage Credit Union </h2>
                                                <small style={{ color: "#fff",  }}>
                                                    Hold while we redirect you to your banking dashboard.
                                                </small>

                                                    <br />
                                                    <br />
                                                <div class="spinner-border text-light" role="status">
                                                    <span class="sr-only">  </span>
                                                </div>

                                            </div>

                                        </div>



                                      



                                    </div>

                                
                                </div>




                                   




                               




                        </div>
                     {/* end other content enters here */}




                    </div>                             
                     {/* end container holding main content */}











                     {/* start footer is starts here */}
                        {/* <DashFoot /> */}
                    {/* end footer is starts here */}                            

                </main>




            












   
               
                
               
          
   

            </section>





               










              













            </div>
    
        </>
    );



}

