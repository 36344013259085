import { BrowserRouter, Routes, Route } from 'react-router-dom';


// home pages
import Home from './HomePages/Home';
import About from './HomePages/About';
// import Plans from './HomePages/Plans';
import Contact from './HomePages/Contact';
import Faq from './HomePages/Faq';
import Login from './HomePages/Login';
import Register from './HomePages/Register';
import ForgotPwd from './HomePages/ForgotPwd';
import PageNoteFound from './HomePages/PageNoteFound';
import PersonalBanking from './HomePages/PersonalBanking';
import SavingsAccount from './HomePages/SavingsAccount';
import CurrentAccount from './HomePages/CurrentAccount';
import NoticeAccount from './HomePages/NoticeAccount';
import BusinessBanking from './HomePages/BusinessBanking';
import BusinessAccount from './HomePages/BusinessAccount';
import TradeFinance from './HomePages/TradeFinance';
import PrivateBanking from './HomePages/PrivateBanking';
import CreditCardServices from './HomePages/CreditCardServices';
import DiscretionaryPort from './HomePages/DiscretionaryPort';
import InvestorVisaPort from './HomePages/InvestorVisaPort';
import ExecutionOnlyPort from './HomePages/ExecutionOnlyPort';
import MortgageLoan from './HomePages/MortgageLoan';
import LoansCredit from './HomePages/LoansCredit';
import PropertyLoans from './HomePages/PropertyLoans';



// dashboard pages
import DashIndex from "./DashPages/DashIndex";
import DashAsset from "./DashPages/DashAsset";
import DashDeposit from "./DashPages/DashDeposit";
import DashWithdraw from "./DashPages/DashWithdraw";
import DashReferral from "./DashPages/DashReferral";
import DashKyc from "./DashPages/DashKyc";
import DashCard from "./DashPages/DashCard";
import DashTransaction from "./DashPages/DashTransaction";
import DashActiveTrades from "./DashPages/DashActiveTrades";
import DashProfile from "./DashPages/DashProfile";
import DashSettings from "./DashPages/DashSettings";
import DashAccountSettings from "./DashPages/DashAccountSettings";
import DashAdvancedSettings from "./DashPages/DashAdvancedSettings";
import DashNotification from "./DashPages/DashNotification";
import DashGroupMsg from "./DashPages/DashGroupMsg";
import DashViewDownlines from "./DashPages/DashViewDownlines";
import DashPaymentPage from "./DashPages/DashPaymentPage";
import InternationTransfer from "./DashPages/InternationTransfer";
import FirstCode from "./DashPages/FirstCode";
import SecondCode from "./DashPages/SecondCode";
import Thirdcode from "./DashPages/Thirdcode";
import ConfirmTransaction from "./DashPages/ConfirmTransaction";
import TransferSuccessful from "./DashPages/TransferSuccessful";
import ViewReciept from "./DashPages/ViewReciept";
import Localtransfer from "./DashPages/Localtransfer";
import DepositViaCrypto from "./DashPages/DepositViaCrypto";
import ApplyLoan from "./DashPages/ApplyLoan";
import SplashScreen from "./DashPages/SplashScreen";
import WithdrawViaCrypto from "./DashPages/WithdrawViaCrypto";
import AccountSetUp1 from "./DashPages/AccountSetUp1";
import AccountSetUp2 from "./DashPages/AccountSetUp2";
import AccountSetUp3 from "./DashPages/AccountSetUp3";
import AccountSetUpDeposit from "./DashPages/AccountSetUpDeposit";
import AccountSetUpDepositCrypto from "./DashPages/AccountSetUpDepositCrypto";
import AccountSetUpDepositCryptoPayment from "./DashPages/AccountSetUpDepositCryptoPayment";



function App() {


  return (
        <>

  <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route  path='/about' element={<About />} />
        {/* <Route  path='/plans' element={<Plans />} /> */}
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/faq' element={<Faq />} />
        <Route exact path='/personalbanking' element={<PersonalBanking />} />
        <Route exact path='/savingsaccount' element={<SavingsAccount />} />
        <Route exact path='/currentaccount' element={<CurrentAccount />} />
        <Route exact path='/noticeaccount' element={<NoticeAccount />} />
        <Route exact path='/businessbanking' element={<BusinessBanking />} />
        <Route exact path='/businessaccount' element={<BusinessAccount />} />
        <Route exact path='/tradefinance' element={<TradeFinance />} />
        <Route exact path='/privatebanking' element={<PrivateBanking />} />
        <Route exact path='/creditcardservices' element={<CreditCardServices />} />
        <Route exact path='/discretionaryport' element={<DiscretionaryPort />} />
        <Route exact path='/investorvisaport' element={<InvestorVisaPort />} />
        <Route exact path='/executiononlyport' element={<ExecutionOnlyPort />} />
        <Route exact path='/loanscredit' element={<LoansCredit />} />
        <Route exact path='/mortgageloans' element={<MortgageLoan />} />
        <Route exact path='/propertyloans' element={<PropertyLoans />} />




        <Route  path='/login' element={<Login />} />
        <Route  path='/register' element={<Register />} />
        <Route  path='/forgotpwd' element={<ForgotPwd />} />
        



       



            {/* dashboard routes */}
            <Route exact path='/dashboard' element={<DashIndex />} />
            <Route exact path='/dashasset' element={<DashAsset />} />
            <Route exact path='/dashdeposit' element={<DashDeposit />} />
            <Route exact path='/dashwithdraw' element={<DashWithdraw />} />
            <Route exact path='/dashreferral' element={<DashReferral />} />
            <Route exact path='/dashkyc' element={<DashKyc />} />
            <Route exact path='/dashcard' element={<DashCard />} />
            <Route exact path='/dashtransaction' element={<DashTransaction />} />
            <Route exact path='/dashactivetrades' element={<DashActiveTrades />} />
            <Route exact path='/dashprofile' element={<DashProfile />} />
            <Route exact path='/dashsettings' element={<DashAccountSettings />} />
            {/* <Route exact path='/dashsettings' element={<DashSettings />} /> */}
            <Route exact path='/dashaccountsettings' element={<DashAccountSettings />} />
            <Route exact path='/dashadvancedsettings' element={<DashAdvancedSettings />} />
            <Route exact path='/dashnotification' element={<DashNotification />} />
            <Route exact path='/dashgroupmsg' element={<DashGroupMsg />} />
            <Route exact path='/dashviewdownlines' element={<DashViewDownlines />} />
            <Route exact path='/dashpaymentpage' element={<DashPaymentPage />} />

            <Route exact path='/internationaltransferpage' element={<InternationTransfer />} />
            <Route exact path='/firstsecuritycode' element={<FirstCode />} />
            <Route exact path='/secondsecuritycode' element={<SecondCode />} />
            <Route exact path='/thirdsecuritycode' element={<Thirdcode />} />
            <Route exact path='/confirmtransaction' element={<ConfirmTransaction />} />
            <Route exact path='/transactionsuccessful' element={<TransferSuccessful />} />
            <Route exact path='/userviewtransactionreceipt' element={<ViewReciept />} />
            <Route exact path='/localtransfer' element={<Localtransfer />} />
            <Route exact path='/depositviacrypto' element={<DepositViaCrypto />} />
            <Route exact path='/applyloan' element={<ApplyLoan />} />
            <Route exact path='/splashscreen' element={<SplashScreen />} />
            <Route exact path='/withdrawviacrypto' element={<WithdrawViaCrypto />} />

            <Route exact path='/accountSetUp1' element={<AccountSetUp1 />} />
            <Route exact path='/accountSetUp2' element={<AccountSetUp2 />} />
            <Route exact path='/accountSetUp3' element={<AccountSetUp3 />} />

            <Route exact path='/accountSetUpDeposit' element={<AccountSetUpDeposit />} />
            <Route exact path='/accountSetUpDepositcrypto' element={<AccountSetUpDepositCrypto />} />
            <Route exact path='/accountSetUpDepositcryptopayment' element={<AccountSetUpDepositCryptoPayment />} />




        <Route  path='*' element={<PageNoteFound />} />

      </Routes>
    </BrowserRouter>
          
        
        
        
        
        
        
        </>



  );
}

export default App;
