
import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";



// import dash images
import assetimg from "../DashImg/assetimg.svg";





export default function DashAsset(){

    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                     {/* box 1 */}
                                     <div className="mainContent_1_Container_box_1">
                                            <div className="mainContent_1_Container_box_1_Content">
                                                <h2>Total Asset</h2>

                                                <p className="">$50,000</p>

                                                <small className="mainContent_1_Container_box_1_Content_small">0.00134 BTC</small>
                                            </div>

                                        </div>



                                {/* list of assets */}
                                <div className="listofAssetContainer">
                                    <div className="listofAssetContainer_Sub">
                                        
                                        <h3>Lists Of Assets</h3>

                                        {/* assetbox1 */}
                                        <div className="assetBox_1">
                                            <div className="assetBox_1_1">
                                                <h2>Active Investment</h2>

                                                <p className="">$0</p>

                                                <small className="">0.00123 BTC</small>

                                            </div>

                                            <div className="assetBox_1_1">
                                                <h2>Active Profits</h2>

                                                <p className="">$0</p>

                                                <small className="">0.00123 BTC</small>

                                            </div>
                                        </div>

                                         {/* END assetbox1 */}
                                        <br />


                                       {/* start assetbox1 */}
                                        <div className="assetBox_1">
                                            <div className="assetBox_1_1">
                                                <h2>Pedning Deposits</h2>

                                                <p className="">$0</p>

                                                <small className="">0.00123 BTC</small>

                                            </div>

                                            <div className="assetBox_1_1">
                                                <h2>Total Deposits</h2>

                                                <p className="">$0</p>

                                                <small className="">0.00123 BTC</small>

                                            </div>
                                        </div>

                                         {/* end assetbox1 */}

                                        <br />

                                        
                                        {/* start assetbox1 */}
                                        <div className="assetBox_1">
                                            <div className="assetBox_1_1 ">
                                                <h2>Pending Withdrawals</h2>

                                                <p className="">$0</p>

                                                <small className="">0.00123 BTC</small>

                                            </div>

                                            <div className="assetBox_1_1 ">
                                                <h2>Total Withdrawals</h2>

                                                <p className="">$0</p>

                                                <small className="">0.00123 BTC</small>

                                            </div>
                                        </div>
                                         {/* end assetbox1 */}






                                    </div>

                                </div>




















                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                    <img src={assetimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 <DashFoot />
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}