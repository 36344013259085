
import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';

import {Link } from "react-router-dom";

export default function Home () {
    return (
        <>
        
            <Head />
        

        {/* home section */}



{/* section 1 */}
        <section>

            <div className='showCaseContainer'>
                <div className='showCaseContainer_Sub'>

                    <div className='showCaseContainer_Sub_1'>

                        <div className='showCaseContainer_Sub_1_Container'>
                            <h3 className='showcaseHeadings_1'>  </h3>
                            <p className='showcasePara_1'>
                               
                            </p>

                            <div className='showCaseButtonContainer'>

                                <div className='showCaseButtonHideMobile'>

                                    <div className='showCaseButton_1'>
                                        <Link to="/login" className='showCaseButton_1_a' style={{ textTransform: "uppercase", }}>Login Your Online Banking</Link>
                                    </div>

                                    <div className='showCaseButton_2'>
                                        {/* <a href="/login">Login</a> */}
                                    </div>


                                    
                                    <p className="clearx"></p>
                                </div>

                               

                                <p className='showCaseParaBelow' style={{color: "#fff"}}>
                                    Trusted By More Than 5M+ Users.
                                </p>
                            </div>
                        </div>
                    </div>




                </div>
            </div>

        </section>




        
        

        {/* section 2 */}
        <section>
                <div className='aboutSection'>

                   

                    <div className='aboutSection_sub2'>
                        <div className='aboutSection_sub2_1'>
                            <div className='aboutSection_sub2_1_img'> </div>
                        </div>
                        <div className='aboutSection_sub2_2'>

                                <h2>A leading international bank you can trust.</h2>

            
                                <p style={{ color: "#333", }}>
                                We got our start in USA, but we’ve been serving customers across the globe since 1988. It’s our mission to help you prosper. So we treat you and your money the way we all want to be treated—with respect. How do we do it? With simple ways to spend, save, and manage your money better. From teaching your high schooler how to set up and use a checking account to helping you buy your first home, we’ll be there, right by your side. Because, at Fortune Trustcorp Bank, we believe respecting you and your money is the first step on the journey to prosperity.



                                </p>

                                <p style={{ color: "#333", }}>
                                We take pride in everything we do. That’s why we aim to exceed your expectations and return outstanding value through our wide range of products and services.


                                </p>

                                <p style={{ color: "#333", }}>
                                A better world is important to us too. That’s why we work closely with not-for-profits and community organisations and support their initiatives through fundraising, donations and volunteering.


                                </p>

                                <h5> <b>  Our Mission: </b> </h5>
                                <p style={{ color: "#333", }}>
                                We are focused on building and sustaining long-term generational relationships with our customers
                                </p>



                                <h5> <b>Our Vision: </b> </h5>

                                <p style={{ color: "#333", }}>
                                Arbitrage Credit Union will serve all over the world and becomes the most popular Bank in this universe.


                                </p>
                                <h5><b>Our Goal:</b></h5>
                                <p style={{ color: "#333", }}>
                                Arbitrage Credit Union will serve their customers from all over the world and becomes the popular bank in this universe.


                                </p>

                                <Link to="/register" style={{ textDecoration: "none" }}>
                               
                                    <div className='companyCert'>
                                        <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                    </div>
                                </Link>

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>
        






        {/* section 3 */}
        <section>
                <div className='whyChooseUsSection'>
                    <div className='whyChooseUsSectionContainer'>

                            <div className='whyChooseUsSectionContainerHeading'>
                                <h2>Reasons Why We Are The Best.</h2>
                                <p>
                                
                                We set standards for sustainable business practices that; unleash the talents of our employees, deliver superior value to our customers and provide innovative solutions for the markets and communities we serve.

                                </p>
                            </div>


                            <div className='whyChooseUsSectionContainerDetails'>

                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Secure Checking</h2>
                                        <p>
                                        Simply the safest and smartest way to bank.
                                        </p>
                                    </div>
                                    
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Trust Services</h2>
                                        <p>
                                        Arbitrage Credit Union offers a wealth of financial resources, click below to find out more.
                                        </p>
                                    </div>
                                    

                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>

                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Mobile Banking</h2>
                                        <p>
                                        Banking at your fingertips, anytime, anywhere!
                                        </p>
                                    </div>
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Business Banking</h2>
                                        <p>
                                        Your next opportunity begins at Arbitrage Credit Union.
                                        </p>
                                    </div>

                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Mortgage</h2>
                                        <p>
                                        When it comes to home financing, you’ll find the best options at Arbitrage Credit Union.
                                        
                                        </p>
                                    </div>
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>24/7 Support</h2>
                                        <p>
                                        We provide 24/7 customer support through e-mail and live chat. Our support representatives are periodically available to elucidate any difficulties.
                                        </p>
                                    </div>

                                </div>

                                <p className="clearx"></p>
                            </div>



                    </div>


                </div>

        </section>
        






        {/* section 4 */}
        <section>
            <div className='sectionFourContainer'>
                <div className='sectionFourContainerSub'>

                    <div className='sectionFourContainerSubCard1'></div>

                    <div className='sectionFourContainerSubCard2'>
                        <div className='sectionFourContainerSubCard2Details'>

                            <h3>
                            It's easy to join with Us
                            </h3>


                            <div className='stepsContainer'>

                                <h5>Open an Account: </h5>
                                <p style={{ color: "#333", }}>
                                To be an account holder you have to open an account first.
                                </p>

                                <h5>Verification: </h5>
                                <p style={{ color: "#333", }}>
                                After registration you need to verify your Email and Mobile Number.
                                </p>

                                <h5>Deposit: </h5>
                                <p style={{ color: "#333", }}>
                                    Deposit some funds into your account to activate the account and keep it active with a minimum of $400.


                                </p>

                                <h5>Get Service: </h5>
                                <p style={{ color: "#333", }}>
                                Now you can get any of our services as our registered account-holder.



                                </p>


                            </div>
                            
                        </div>
                    </div>

                </div>

            </div>

        </section>
        



            {/* faq section */}
            <section>
                <div className='faqContainer'>
                    <div className='faqContainerSub'>
                        <h2>Frequently Asked Questions</h2>
                        <p style={{ color: "#333", }}>
                            We have answered some of your Questions regarding our platform. If you still need help, Please contact us.
                        </p>



                        <div className='faqContainerSubForm'>

                            {/* start */}
            

                                <div class="accordion" id="accordionExample" >



                                    {/* start */}
                                    <div className="accordion-item" >
                                        <h2 className="accordion-header" id="headingOne" >
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"  style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        Is opening an account is free?
                                        </button>
                                        </h2>

                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div className="accordion-body">
                                        
                                        Yes, we don&#039;t take any fees for opening an account.

                                       </div>
                                        </div>

                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        Is it possible to send money from Arbitrage Credit Union to another bank?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        Yes, you can send money from Arbitrage Credit Union to another bank.
                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo1">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                            How to open an account?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo1" class="accordion-collapse collapse" aria-labelledby="headingTwo1" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        Get the registration form by clicking on the Sing Up button on the top bar. Provide all information and click on the Sign Up button.
                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo2">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        How do i reset my password?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo2" class="accordion-collapse collapse" aria-labelledby="headingTwo2" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        If you have lost your pasword or you want to change it to a new one. Please click on the forgot password option and follow the steps to retrieve your password.
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo3">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        How to take a loan?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo3" class="accordion-collapse collapse" aria-labelledby="headingTwo3" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#092980"}}>
                                        <div class="accordion-body">
                                        We have several loan plans. Choose the best plan suitable for you and just click on the Apply Now button and put the amount.

                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo4">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo4" aria-expanded="false" aria-controls="collapseTwo4" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                       What do I do if I lose my card or it gets stolen?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo4" class="accordion-collapse collapse" aria-labelledby="headingTwo4" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        If your card is missing, let us know immediately. We’ll block your card
                                            right away send over a new one on the same day.To report a lost or
                                            stolen card, contact us!
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo5">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo5" aria-expanded="false" aria-controls="collapseTwo5" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        Is Storm Trust Finance digital banking secure?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo5" class="accordion-collapse collapse" aria-labelledby="headingTwo5" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        Yes. 100% Security. We measure up to all the reliable security operations.
                                        </div>
                                        </div>
                                    </div>


                                    
                                </div>

                            {/* end */}
                        </div>


                    </div>


                </div>   
            </section>




                {/* referral */}
                <section>
                        <div className='referralContainer'>

                            <div className='referralContainerSub'>
                                <h2>We provide our banking services all over the world</h2>
                                <p>
                                Arbitrage Credit Union is a secure and robust e-Banking system getting popular all over the world nowadays. We offer the best FDR, DPS &amp; Loan plans to our account holders


                                </p>

                                <center>
                                <Link to="/register" className='referralContainerSub_Ppp' style={{ textTransform: "uppercase", }}>Open an account</Link>
                                </center>
                                
                            </div>

                        </div>


                </section>





            {/* debit card */}
                <section>

                    <div className='cryptoDebitCardContainer'>
                        <div className='cryptoDebitCardContainerSub'>

                            <div className='cryptoDebitCardContainerSubCard1'></div>

                            <div className='cryptoDebitCardContainerSubCard2'>
                                    <h2> Debit or Credit Card</h2>

                                    <p>
                                    Get easy access to your money anytime, anywhere in the world with your FirstBank cards. Keep tabs on your money with your debit cards, cover rainy-day expenses with your credit cards and manage your spending with your prepaid cards.



                                    </p>

                                    
                                    <Link to="/login" style={{ textTransform: "uppercase", }}>Apply Now</Link>
                                
                                    
                            </div>

                            <p className="clearx"></p>
                        </div>

                    </div>

                </section>





            {/* testionial */}
            <section>
                    <div className='testimonailContainer'>
                        <div className='testimonailContainerSub'>
                        
                            <h2>Our Services</h2>
                            <p>
                            We make your life comfortable with our services.
                            </p>


                                <div className='testimonailCardContainer'>

                                    <div className='testimonailCard'>
                                        <div className='testimonailCardDetails'>




                                            <div className='testimonailCardDetails_Center'>

                                                <center>
                                                    <div className='testImage'>
                                                        {/* <img src="test/pro1.svg" alt="" width={50} height={50} /> */}
                                                        {/* <i class="bi bi-arrow-left-right"></i> */}
                                                    </div>
                                                </center>

                                                <h3>Fast Transfer</h3>
                                                <p className='testimonailCardDetails1'>
                                                Our Money transfer system is secure and easy. Send your funds to your beneficiaries within Arbitrage Credit Union or to other banks. Transfer within Arbitrage Credit Union is instant and to other banks may take 24 hours.
                                                </p>

                                            </div>
                                                

                                              



                                        </div>
                                    </div>






                                    
                                    <div className='testimonailCard'>
                                        <div className='testimonailCardDetails'>

                                               

                                                <div className='testimonailCardDetails_Center'>

                                                <center>
                                                    <div className='testImage'>
                                                        {/* <img src="test/pro1.svg" alt="" width={50} height={50} /> */}
                                                        {/* <i class="bi bi-arrow-left-right"></i> */}
                                                    </div>
                                                </center>

                                                <h3>Deposit Funds</h3>
                                                <p className='testimonailCardDetails1'>
                                                Account-holders of Arbitrage Credit Union are able to deposit their money through our several payment systems. We have online payment services like PayPal, Stripe, Paystack, Skrill, Flutterwave, Mollie, Payeer, etc.


                                                </p>

                                            </div>


                                        </div>
                                    </div>


                                    <div className='testimonailCard'>
                                        <div className='testimonailCardDetails'>

                                                

                                                 <div className='testimonailCardDetails_Center'>

                                                <center>
                                                    <div className='testImage'>
                                                        {/* <img src="test/pro1.svg" alt="" width={50} height={50} /> */}
                                                        {/* <i class="bi bi-arrow-left-right"></i> */}
                                                    </div>
                                                </center>

                                                <h3>Withdraw Funds</h3>
                                                <p className='testimonailCardDetails1'>
                                                Account-holders of Arbitrage Credit Union are able to withdraw money from their account. Without verification, any withdrawal won't be completed, so you can trust Arbitrage Credit Union.


                                                </p>

                                            </div>


                                        </div>
                                    </div>



                                   
                                   
                                   
                                    <p className="clearx"></p>
                                </div>


                        </div>
                    </div>
            </section>







{/* debit card */}
                <section>
                

                    <div className='cryptoDebitCardContainer' style={{ 
                        height: "auto"
                     }}>
                        <div className='cryptoDebitCardContainerSub'>

                            <div className='cryptoDebitCardContainerSubCard1Mobile'></div>

                            <div className='cryptoDebitCardContainerSubCard2'>
                                    <h2> Our Mobile App</h2>

                                    <p>
                                    Secure, convenient banking with our Mobile app. Get customized guidance and easy‑to‑use digital tools to help you make the most of your finances.

                                    </p>

                                    
                                    {/* <a href="" style={{ textTransform: "uppercase", }}>download our mobile app</a> */}
                                
                                    
                            </div>

                            <p className="clearx"></p>
                        </div>

                    </div>

                </section>









              {/* referral */}
              <section>
                        <div className='referralContainer' >

                            <div className='referralContainerSub' style={{ backgroundColor: "#092980", height: "auto"}}>
                                <h2 style={{ color: "white", fontWeight: "bold", }}>
                                We don't just help you reach financial milestones, we build lasting relationships.
                                </h2>
                               
                                <p style={{ color: "white", fontSize: 14,}}>
                                
                                As digital pioneers, we integrate innovative technological advancements, like Africa’s first banking chatbot LEO, and prioritise safety and security with cutting-edge infrastructure. Our global presence in 4 continents, 20 African countries, and over 7 decades of expertise, attracts an expanding customer base as we provide superior and sustainable financial services.



                                </p>

                                <center>
                                <Link to="/register" className='referralContainerSub_Ppp' style={{ textTransform: "uppercase", backgroundColor:"white", color: "#092980" }}>Open an account</Link>
                                </center>
                                
                            </div>

                        </div>


                </section>


                <div className='dfffcc'></div>
                        


                {/* footer */}

                <Foot  />








        
        
        
        
        
        
        
        </>
    );
}