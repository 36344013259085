// CurrentAccount








import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function CurrentAccount ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                   <li> <Link to='/personalbanking'> Personal Banking </Link> </li>
                                   <li> <Link to='/savingsaccount'> Savings Account </Link> </li>
                                    {/* <li> <Link to='/currentaccount'> Current Account </Link> </li> */}
                                    <li> <Link to='/noticeaccount'> Notice Deposit Account </Link> </li>
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Notice Deposit Account </h2>

            
                                <p style={{ color: "#333", }}>
                                A Notice Deposit Account is an investment account where money is deposited for an indefinite period of time and the interest paid is based on the period of notice that you select.


                                </p>

                                <p style={{ color: "#333", }}>
                                Our range of Notice Deposit Accounts allow you to benefit from higher interest rates based on the period of notice that you select.


                                </p>


                                <h2> Why choose our Notice Deposit Account? </h2>

                                <p style={{ color: "#333", }}>
                                    1. You will have your own dedicated Relationship Manager that will guide you and help you with all your needs.


                                </p>
                                <p style={{ color: "#333", }}>
                                    2. You can choose to open a US Dollar, GBP or Euro current account to facilitate your international transactions.

                                </p>
                                <p style={{ color: "#333", }}>
                                    3. You can choose from flexible deposit terms of 30, 60 and 90 days’ notice for your convenience.


                                </p>
                                <p style={{ color: "#333", }}>
                                    4. You will be offered competitive rates of interest for a fixed period.


                                </p>
                                <p style={{ color: "#333", }}>
                                    5. No fees or charges will be applied to your notice deposit account.


                                </p>


                               




                                

                                

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}