import { useEffect } from "react";
import $ from 'jquery';
import {Link } from "react-router-dom";





import logo from "../images/logo.png";

export default function Head () {



    useEffect(() => {
        
        window.scrollTo(0, 0);

    },[]);



   

   const  showMobileNav = () =>{

        $("#exampleModal").modal('show');

        
       
    }


    return (    
            <>
                <header>

                <div className="startHeader">
                    <div className="startHeader_1">
                        <p className="startHeader_1_p">Arbitrage Credit Union</p>
                    </div>


                    {/* use js clock here  */}
                    <div className="startHeader_2">
                        {/* <span className="startHeader_2_date">28 Jan, 2024 17.03pm</span> */}

                        <Link to='/login' className="startHeader_2_dateR">Login</Link>
                        <Link to='/register' className="startHeader_2_dateR">Register</Link>
                    </div>
                </div>

                
                    <div className="headerContainer">

                        <div className="headerContainer_Sub">

                            


                                <div className="logoSection">
                                    <Link to='/'>
                                        {/* <h2 style={{ color: "#092980", fontWeight: "bold", fontSize:20, }}> Arbitrage Credit Union </h2> */}
                                        <img src={logo} width={100} className="logoHome"  />
                                    </Link>
                                    
                                    
                                </div>


                                    <div className="navBarContainerx" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    {/* <div className="navBarContainerx" onClick={showMobileNav}> */}
                                        <span className="material-symbols-outlined" style={{ color: "#3333FF", fontWeight: "bold", fontSize:50, }}>
                                            menu
                                        </span>  
                                    </div>




                    <div className="divHoldingMobileNav">

                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content modal-contentHomeNav">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel"style={{ color: "#092980" }} >Menu</h1>
                                    <button type="button" className="btn-close closeNavHoldButton" data-bs-dismiss="modal" aria-label="Close" style={{backgroundColor: "#092980"}}></button>
                                </div>
                                <div className="modal-body">

                                {/* <span class="material-symbols-outlined">
                                home
                                </span> */}

                                        {/* nav content */}
                                    <div className="navContent">
                                        <div className="navContentSub">


                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                            <Link to="/" >
                                                <center>
                                                <span class="material-symbols-outlined" style={{color: "#fff", marginTop: 10,}}>
                                                home
                                                </span>
                                                <br />
                                                 <p data-bs-dismiss="modal"> Home </p> 
                                                </center>
                                                </Link> 
                                            </div>


                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                                <Link to="/about">
                                                 <center>
                                                 <span class="material-symbols-outlined" style={{color: "#fff", marginTop: 10,}}>
                                                   groups
                                                 </span>
                                                <br />
                                                 About 
                                                </center>
                                                </Link> 
                                            </div>







                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                                <Link to="/personalbanking">
                                                <center>
                                                 <span class="material-symbols-outlined" style={{color: "#fff", marginTop: 10,}}>
                                                   payments
                                                 </span>
                                                <br />
                                                 Personal  
                                                </center>
                                                </Link> 
                                            </div>


                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                                <Link to="/businessbanking">
                                                <center>
                                                 <span class="material-symbols-outlined" style={{color: "#fff", marginTop: 10,}}>
                                                   payments
                                                 </span>
                                                <br />
                                                 Business 
                                                </center>
                                                </Link> 
                                            </div>

                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                                <Link to="/privatebanking">
                                                <center>
                                                 <span class="material-symbols-outlined" style={{color: "#fff", marginTop: 10,}}>
                                                   payments
                                                 </span>
                                                <br />
                                                 Private 
                                                </center>
                                                </Link> 
                                            </div>

                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                                <Link to="/loanscredit">
                                                <center>
                                                 <span class="material-symbols-outlined" style={{color: "#fff", marginTop: 10,}}>
                                                   payments
                                                 </span>
                                                <br />
                                                 Loan 
                                                </center>
                                                </Link> 
                                            </div>

























                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                            <Link to="/contact">
                                                <center>
                                                 <span class="material-symbols-outlined" style={{color: "#fff", marginTop: 10,}}>
                                                 support_agent
                                                 </span>
                                                <br />
                                                 Support 
                                                </center>
                                                </Link> 
                                            </div>


                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                            <Link to="/faq">
                                                <center>
                                                 <span class="material-symbols-outlined" style={{color: "#fff", marginTop: 10,}}>
                                                 quiz
                                                 </span>
                                                <br />
                                                 Faq 
                                                </center>
                                                </Link> 
                                            </div>


                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                            <Link to="/login">
                                                <center>
                                                 <span class="material-symbols-outlined" style={{color: "#fff", marginTop: 10,}}>
                                                 logout
                                                 </span>
                                                <br />
                                                 Login 
                                                </center>
                                                </Link> 
                                            </div>


                                            <p className="clearx"></p>
                                        </div>


                                        <Link to='/register'>
                                            <div className="navReg" data-bs-dismiss="modal">
                                                <p>Register</p>
                                            </div>
                                        </Link>
                                       


                                    </div>







                                    
                                    
                                </div>
                               
                                </div>
                            </div>
                        </div>

                    </div>
               




                                <div className="navSection">
                                    <div className="navItems">
                                        <ul>
                                            <li><Link to="/"> Home </Link> </li>
                                            <li><Link to="/personalbanking"> Personal Banking </Link> </li>
                                            <li><Link to="/businessbanking"> Business Banking</Link> </li>
                                            <li><Link to="/privatebanking"> Private Banking</Link> </li>
                                            <li><Link to="/loanscredit"> Loan & Credit </Link> </li>
                                            <li><Link to="/about"> About </Link> </li>
                                            <li><Link to="/contact"> Contact </Link> </li>
                                            <li><Link to="/faq"> faq </Link> </li>



                                            {/* <li><Link to="/about"> About </Link> </li>
                                            <li><Link to="/plans"> Plans </Link> </li>
                                            <li><Link to="/contact"> Contact </Link> </li>
                                            <li><Link to="/faq"> Faq </Link> </li>
                                            <li><Link to="/login"> Login </Link> </li>
                                            <li><Link to="/register"> Register </Link> </li>
                                            <li><span className="googleTrans"> Google Translate </span></li> */}
                                        </ul>
                                    </div>   

                                    <div className="navMobile">
                                    
                                    </div> 
                                    
                                </div>
                                

                               

                                <p className="clearx"></p>





                               
                        </div>


                        <p className="clearx"></p>


                    </div>
                </header>

            

                <div className="navbarx">
  <a href="#home" className="active">Home</a>
  <a href="#news">News</a>
  <a href="#contact">Contact</a>
</div>












{/* modal for the header nav */}

















            </>
    );

}