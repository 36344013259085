// DashAdvancedSettings

// 
// 





// import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";



// import dash images
import set2img from "../DashImg/set2img.svg";
// import proicon from "../DashImg/proicon.svg";

export default function DashAdvancedSettings(){

    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />



        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                        {/* box 1 */}
                                   



                                        {/* box 2 */}

                                                                                
                                        <div className="depositMainContainer">
                                            <div className="depositMainContainer_Sub" style={{height: "auto"}}>
                                                    {/* <h4>Profile Details</h4> */}

                                                    <Link to='/dashsettings' style={{ color: "#fff" }}>
                                                            <p>
                                                            <i class="bi bi-arrow-left-square-fill"></i> Go Back 
                                                            </p>
                                                    </Link>

                                                    <ul class="nav nav-tabs nav-justified" role="tablist">

                                                        <li class="nav-item " role="presentation" >
                                                            <a class="nav-link active settingsTabs" id="justified-tab-0" data-bs-toggle="tab" href="#justified-tabpanel-0" role="tab" aria-controls="justified-tabpanel-0" aria-selected="true"> <span style={{ color: "#fff"}}> 
                                                            Advanced Settings
                                                            </span> </a>
                                                        </li>
                                                        {/* <li class="nav-item" role="presentation">
                                                            <a class="nav-link settingsTabs" id="justified-tab-1" data-bs-toggle="tab" href="#justified-tabpanel-1" role="tab" aria-controls="justified-tabpanel-1" aria-selected="false"> <span style={{ color: "#F0B90B"}}>  Reset OTP Code </span> </a>
                                                        </li> */}
                                                      
                                                    </ul>


                                                <div class="tab-content pt-5" id="tab-content">

                                                    {/* advanced settings*/}
                                                <div class="tab-pane active" id="justified-tabpanel-0" role="tabpanel" aria-labelledby="justified-tab-0">
                                                        

                                                        {/* box1 */}
                                                        <div className="advanceditemContainer">
                                                            <div className="advanceditemContainer_1">
                                                                <p>
                                                                    2FA Authentication (OFF)
                                                                </p>
                                                            </div>
                                                            <div className="advanceditemContainer_2">
                                                                
                                                            <div class="form-check form-switch formswitchCss">

                                                                <input class="form-check-input formswitchCssInput" type="checkbox" role="switch" id="flexSwitchCheckDefault"  />

                                                                {/* <input class="form-check-input formswitchCssInput" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked  /> */}
                                                                
                                                            </div>

                                                            </div>

                                                        </div>

                                                        <br />

                                                        {/* box2 */}
                                                        <div className="advanceditemContainer">
                                                            <div className="advanceditemContainer_1">
                                                                <p>
                                                                    Profile Privacy (ON)
                                                                </p>
                                                            </div>
                                                            <div className="advanceditemContainer_2">
                                                                
                                                            <div class="form-check form-switch formswitchCss">

                                                                {/* <input class="form-check-input formswitchCssInput" type="checkbox" role="switch" id="flexSwitchCheckDefault"  /> */}

                                                                <input class="form-check-input formswitchCssInput" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked  />
                                                                
                                                            </div>

                                                            </div>

                                                        </div>

                                                            <br />

                                                        {/* box3 */}
                                                        <div className="advanceditemContainer">
                                                            <div className="advanceditemContainer_1">
                                                                <p>
                                                                    OTP Code (ON)
                                                                </p>
                                                            </div>
                                                            <div className="advanceditemContainer_2">
                                                                
                                                            <div class="form-check form-switch formswitchCss">

                                                                {/* <input class="form-check-input formswitchCssInput" type="checkbox" role="switch" id="flexSwitchCheckDefault"  /> */}

                                                                <input class="form-check-input formswitchCssInput" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked  />
                                                                
                                                            </div>

                                                            </div>

                                                        </div>

                                                        <br />

                                                        {/* box4 */}
                                                        <div className="advanceditemContainer">
                                                            <div className="advanceditemContainer_1">
                                                                <p>
                                                                     Message Notifications (ON)
                                                                </p>
                                                            </div>
                                                            <div className="advanceditemContainer_2">
                                                                
                                                            <div class="form-check form-switch formswitchCss">

                                                                {/* <input class="form-check-input formswitchCssInput" type="checkbox" role="switch" id="flexSwitchCheckDefault"  /> */}

                                                                <input class="form-check-input formswitchCssInput" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked  />
                                                                
                                                            </div>

                                                            </div>

                                                        </div>

                                                        <br />
                                                        {/* box5 */}
                                                        <div className="advanceditemContainer">
                                                            <div className="advanceditemContainer_1">
                                                                <p>
                                                                    Transaction Notifications (ON)
                                                                </p>
                                                            </div>
                                                            <div className="advanceditemContainer_2">
                                                                
                                                            <div class="form-check form-switch formswitchCss">

                                                                <input class="form-check-input formswitchCssInput" type="checkbox" role="switch" id="flexSwitchCheckDefault"  />

                                                                {/* <input class="form-check-input formswitchCssInput" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked  /> */}
                                                                
                                                            </div>

                                                            </div>

                                                        </div>








                                                    
                                                </div>









                                               

                                                       
                                                




                                                </div>


                                        
                                                
                                                    
                                            </div>

                                              
                                        </div>
























                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={set2img} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}