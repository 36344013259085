

import {Link } from "react-router-dom";




export default function Foot ()
{

    return(
            <>
            
            
            <footer>
                    <div className='footerContainer'>
                        <div className='footerContainerSub'>

                            <div className='footerContainerSubCard'>
                                    <h3>Arbitrage Credit Union</h3>
                                    <p>
                                    
                                    Arbitrage Credit Union is a complete e-Banking system. We have account-holders from almost all over the world. This is getting popular day by day. Our system is secure and robust. You may feel safe about your deposited funds.



                                    </p>
                            </div>


                            <div className='footerContainerSubCard'>
                                <h4>LINKS</h4>
                                <ul>
                                    <li><Link to='/'>Home </Link></li>
                                    <li><Link to='/about'>About  </Link></li>
                                    <li><Link to='/faq'> Faq </Link></li>
                                    <li><Link to='/contact'> Contact </Link> </li>
                                </ul>
                            </div>
                            <div className='footerContainerSubCard'>
                                <h3>CONTACT</h3>
                                    <p>
                                        Office:<br /> 1201 Louisiana St
FL 35
Houston, Texas 
United States


                                    </p>

                                    <p>
                                        Email: <br />
                                        support@arbitragecreditunion.com
                                    </p>

                            </div>

                            <p className="clearx"></p>

                            <p className='footLastText'>
                                © 2024 Arbitrage Credit Union. All Rights Reserved.

                            </p>
                        </div>

                    </div>
                </footer>









            
            
            
            
            
            
            </>

    );




}