// CreditCardServices




import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function CreditCardServices ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                   <li> <Link to='/privatebanking'> Private Banking </Link> </li>
                                   {/* <li> <Link to='/creditcardservices'> Credit Card Services </Link> </li> */}
                                    <li> <Link to='/discretionaryport'> Discretionary Portfolios </Link> </li>
                                    <li> <Link to='/investorvisaport'> Investor Visa Portfolios </Link> </li>
                                    <li> <Link to='/executiononlyport'> Execution Only Portfolios </Link> </li>
                                    
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Credit Card Services </h2>

            
                                <p style={{ color: "#333", }}>
                                We offer a range of credit cards available in USD, EUR and GBP.




                                </p>
                                <p style={{ color: "#333", }}>
                                Is this product right for you?



                                </p>


                                <p style={{ color: "#333", }}>
                                Yes, if you are an existing High Net Worth customer or a senior level professional and you want to enjoy the benefits available.




                                </p>
                               


                                <h2> Why choose our Credit Card Services? </h2>
                                <p style={{ color: "#333", }}>
                                    1. We will process your application quickly and hassle free.

                                </p>
                                <p style={{ color: "#333", }}>
                                    2. You will have a 24/7 access to global assistance and customer service.


                                </p>
                                <p style={{ color: "#333", }}>
                                    3. Credit Cards are available in USD, EUR and GBP.

                                </p>
                                <p style={{ color: "#333", }}>
                                    4. You can use your credit card at over 900,000 ATMs worldwide.

                                </p>
                                <p style={{ color: "#333", }}>
                                    5. You will be offered comprehensive travel insurance for you and your family.

                                </p>
                                <p style={{ color: "#333", }}>
                                    6. You will be offered extended warranty, purchase and refund protection as well as emergency credit card replacement service.

                                </p>
                                <p style={{ color: "#333", }}>
                                    7. Your family and friends can benefit from your membership by becoming supplementary credit card holders.

                                </p>

                                <p style={{ color: "#333", }}>
                                    8. You can manage your account any time, any day online.

                                </p>
                                <p style={{ color: "#333", }}>
                                    9. There is no pre-set spending limit and no interest charges if the balance is settled in accordance with credit card conditions.

                                </p>

                                

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}