// DashReferral


import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";



// import dash images
import referimg from "../DashImg/referimg.svg";

export default function DashReferral(){

    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                        {/* box 1 */}
                                    <div className="mainContent_1_Container_box_1">
                                            <div className="mainContent_1_Container_box_1_Content">
                                                <h2>Total Referral Bonus</h2>

                                                <p className="">$50.00</p>

                                                <small className="mainContent_1_Container_box_1_Content_small">0.00134 BTC</small>
                                            </div>

                                        </div>



                                        {/* box 2 */}

                                                                                
                                        <div className="depositMainContainer">
                                            <div className="depositMainContainer_Sub">

                                                <p className="refDetails">
                                                Our company offers you 15% referral commission whenever you refer your
                                                friends or family to our website to invest.
                                                </p>

                                            <form>
                                               

                                               

                                                
                                                <label htmlFor="fullname" className='depositFormLabel'>Your Referral Link. </label> <br />
                                                
                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="xxxxx"   className='DepositformInputAmount' readOnly />
                                                        </div>
                                                    </div>


                                                <br /> 
                                                


                                                {/* submit button */}

                                                <button className="depositProceedButton">Copy Referral Link </button>

                                            </form>
                                                
                                                <br />
                                                <br />
                                            <center>

                                                <h2 className="downlineNumber">Total Downline: 0</h2>


                                                <Link to='/dashviewdownlines' style={{textDecoration: "underline", }}>Click here to view your downlines here</Link>

                                            </center>
                                            
                                                    
                                            </div>

                                              
                                        </div>

                                                        
                                                       






















                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={referimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 <DashFoot />
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}