// NoticeAccount








import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function NoticeAccount ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                   <li> <Link to='/personalbanking'> Personal Banking </Link> </li>
                                   <li> <Link to='/savingsaccount'> Savings Account </Link> </li>
                                    <li> <Link to='/currentaccount'> Current Account </Link> </li>
                                    {/* <li> <Link to='/noticeaccount'> Notice Deposit Account </Link> </li> */}
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Savings Account </h2>

            
                                <p style={{ color: "#333", }}>
                                Save and earn highly competitive interest monthly. Our savings account lets you save towards various goals including your emergency fund with the peace of mind that comes from knowing you are ready for the unexpected.



                                </p>


                                <h2> What does it offer you:</h2>
                                <p style={{ color: "#333", }}>
                                    1. Earns interest at highly competitive rates.

                                </p>
                                <p style={{ color: "#333", }}>
                                    2. You get a MasterCard debit card so you can access your money at any time.

                                </p>
                                <p style={{ color: "#333", }}>
                                    3. Full e-banking options including internet banking, mobile banking and ATMs.

                                </p>
                                <p style={{ color: "#333", }}>
                                    4. You get access to a multilingual customer care centre, which is open 24/7.

                                </p>
                                <p style={{ color: "#333", }}>
                                    5. Cheque pay-in is available without reference.

                                </p>

                                

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}