// DashActiveTrades






// import $ from "jquery";
// import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";



// import dash images
// import kycimg from "../DashImg/kycimg.svg";

export default function DashActiveTrades(){

    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1" >
                                <div className="mainContent_1_Container">
                                    
                                        {/* box 1 */}
                                        {/* <div className="mainContent_1_Container_box_1">
                                            <div className="mainContent_1_Container_box_1_Content">
                                                <h2>Total Referral Bonus</h2>

                                                <p className="">$50.00</p>

                                                <small className="mainContent_1_Container_box_1_Content_small">0.00134 BTC</small>
                                            </div>

                                        </div> */}



                                        {/* box 2 */}

                                                                                
                                       {/* table div container */}
                                        <div className="transactionContainer">
                                            <div className="transactionContainer_Sub">
                                                <h2>Active Trades</h2>

                                                <div className="transactionContainer_Sub_table">
                                                    



                                                <table class="table">
                                                    <thead class="thead-dark">
                                                        <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">First</th>
                                                        <th scope="col">Last</th>
                                                        <th scope="col">Handle</th>
                                                        </tr>
                                                    </thead>


                                                    <tbody>
                                                        <tr>
                                                        <th scope="row">1</th>
                                                        <td>Mark</td>
                                                        <td>Otto</td>
                                                        <td>@mdo</td>
                                                        </tr>
                                                        <tr>
                                                        <th scope="row">2</th>
                                                        <td>Jacob</td>
                                                        <td>Thornton</td>
                                                        <td>@fat</td>
                                                        </tr>
                                                        <tr>
                                                        <th scope="row">3</th>
                                                        <td>Larry</td>
                                                        <td>the Bird</td>
                                                        <td>@twitter</td>
                                                        </tr>
                                                    </tbody>


                                                    
                                                </table>



                                                </div>



                                            </div>
                                        </div>






















                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            {/* <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={kycimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div> */}





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 <DashFoot />
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}