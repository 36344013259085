
export default function About(){
    return (
        <>
              <center>
              <h2 style={{
                color: "#F0B90B",
                padding:20,
              }}>Page Not Found</h2>
              </center>
              
        </>


    );

}