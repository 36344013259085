// ExecutionOnlyPort








import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function ExecutionOnlyPort ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                   <li> <Link to='/privatebanking'> Private Banking </Link> </li>
                                   <li> <Link to='/creditcardservices'> Credit Card Services </Link> </li>
                                    <li> <Link to='/discretionaryport'> Discretionary Portfolios </Link> </li>
                                    <li> <Link to='/investorvisaport'> Investor Visa Portfolios </Link> </li>
                                    {/* <li> <Link to='/executiononlyport'> Execution Only Portfolios </Link> </li> */}
                                    
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Execution Only Portfolios </h2>

            
                                <p style={{ color: "#333", }}>
                                If you wish to invest in simple products within global markets using your own investment expertise this portfolio is for you. Perhaps, you have an interest in an individual company, market or product, this portfolio will give you the freedom to manage your own investments.







                                </p>
                                <p style={{ color: "#333", }}>
                                Is this service right for you?



                                </p>


                                <p style={{ color: "#333", }}>
                                Yes, if you are a confident investor who wishes to manage your own investments.





                                </p>
                               


                                <h2> Why choose our Execution Only Portfolios? </h2>

                                <p style={{ color: "#333", }}>
                                    1. If you are confident to make your own investment decisions or if you use a financial advisor you can use this portfolio to execute your trades.

                                </p>
                                <p style={{ color: "#333", }}>
                                    2. You will have online access to easily monitor transactions and view portfolio balances.


                                </p>
                                <p style={{ color: "#333", }}>
                                    3. We have a dedicated execution desk, with personal service and timely trading.

                                </p>
                                <p style={{ color: "#333", }}>
                                    4. You will have access to global markets to help diversify your investments.

                                </p>
                                {/* <p style={{ color: "#333", }}>
                                    5. Your investment will be protected under USA regulations (FCA & PRA).

                                </p> */}
                                {/* 
                                <p style={{ color: "#333", }}>
                                    6. You will be offered extended warranty, purchase and refund protection as well as emergency credit card replacement service.

                                </p>
                                <p style={{ color: "#333", }}>
                                    7. Your family and friends can benefit from your membership by becoming supplementary credit card holders.

                                </p>

                                <p style={{ color: "#333", }}>
                                    8. You can manage your account any time, any day online.

                                </p>
                                <p style={{ color: "#333", }}>
                                    9. There is no pre-set spending limit and no interest charges if the balance is settled in accordance with credit card conditions.

                                </p> */}

                                

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}