// DashAccountSettings
// 





import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";


import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


// import dash images
import set1img from "../DashImg/set1img.svg";
// import proicon from "../DashImg/proicon.svg";

export default function DashAccountSettings(){
    const navigateRoutes = useNavigate();
    const [errMsg, setErrMsg] = useState("");


    const [oldPass, setoldPass] = useState("");
    const [newPass, setnewPass] = useState("");
    const [cnewPass, setcnewPass] = useState("");
    

    const [oldPin, setoldPin] = useState("");
    const [newPin, setnewPin] = useState("");
    const [cnewPin, setcnewPin] = useState("");


    const [msgLabel, setMsgLabel] = useState("");
    const [msgPara, setMsgPara] = useState("");


    const hideModalContainerMsg = () => {
        $("#showModalContainerMsg").hide();

        navigateRoutes('/dashboard');
        
        // $("#depositProceedButton").show();
        // $("#processingButton").hide();
    }





    const confirmRequest = (e) => {

        e.preventDefault();

        $("#depositProceedButton").hide();
        $("#processingButton").show();

    
        if(oldPass == "" || newPass == ""){
            toast.error("Enter Password Details");
            setErrMsg("Enter  Password Details");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;


        }else if(oldPass.length < 6  || oldPass.length > 12 ){
            toast.error("Enter your  current account password");
            setErrMsg("Enter your  current account password");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;
    

        }else if(newPass.length < 6 ){
            toast.error("Minimum password character is 6.");
            setErrMsg("Minimum password character is 6.");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if( newPass.length > 12){
            toast.error("Maximum password character is 12.");
            setErrMsg("Maximum password character is 12.");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

    

        }else if(cnewPass != newPass){
            toast.error("Your new password and comfirm password does not match");
            setErrMsg("Your new password and comfirm password does not match");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;
    

        }else{


            setErrMsg("");

            // starts here 
            const checkData1 = localStorage.getItem('tokenID');
            const checkData2 = localStorage.getItem('currentUserName');
            
            if(checkData1 && checkData2){
                let urls = "http://127.0.0.1:8000/api/changeuserpasswordapi";
            
                async function proceedToSendDataToServer (){
                    try{
                        const registerPostFeedBack = await axios.post(urls,{
            
                            tokenCode: checkData1,
                            userName: checkData2,

                            oldPass: oldPass,
                            newPass: newPass,
                           
                           

            
                        }).then((res) => {
            
                            let feedbackMsg = res.data.msg;
                            let feedbackStatusCode = res.data.status;
            
            
                            if(feedbackStatusCode == 200){
                                toast.success(feedbackMsg);
                                
                                setMsgLabel("Password Reset");
                                setMsgPara("Password reset  was successful, your password has been updated.");
                                setTimeout(() => {
                                    $("#showModalContainerMsg").show();
                                  }, 1000);
                              
                            }

            
                            if(feedbackStatusCode == 402){
                                toast.error(feedbackMsg);
                                setErrMsg(feedbackMsg);
                                $("#errMsg").show();
                                $("#depositProceedButton").show();
                                $("#processingButton").hide();
                            }
            
                            if(feedbackStatusCode == 401){
                                navigateRoutes('/login', { replace: true });
                            }
            
                            if(feedbackStatusCode == 501){
                                navigateRoutes('/login', { replace: true });
                            }
            
                            
            
                        });
            
                   }catch (err){
            
                    toast.error("Error connecting to the servers.");
                    setErrMsg("Error connecting to the servers.");
                    $("#depositProceedButton").show();
                    $("#processingButton").hide();
             
                    //  console.log(err);
            
                 }
            
            
                }
                proceedToSendDataToServer();
            
            
            }else{
            
            navigateRoutes('/login', { replace: true });
            }
            
            

        }


    
    
    }





    // reset acct pin
    const confirmPinRequest = (e) => {

        e.preventDefault();

        $("#depositProceedButton").hide();
        $("#processingButton").show();

    
        if(oldPin == "" || newPin == ""){
            toast.error("Enter transaction Pin Details");
            setErrMsg("Enter transaction Pin Details");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;


        }else if(oldPin.length < 4  || oldPin.length > 4 ){
            toast.error("Enter your  current transaction pin");
            setErrMsg("Enter your  current transaction pin");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;
    

        }else if(newPin.length < 4 ){
            toast.error("Minimum transaction pin character is 4.");
            setErrMsg("Minimum transaction pin character is 4.");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if( newPin.length > 4){
            toast.error("Maximum newPin character is 4.");
            setErrMsg("Maximum newPin character is 4.");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

    

        }else if(cnewPin != newPin){
            toast.error("Your new transaction pin and comfirm transaction pin does not match");
            setErrMsg("Your new transaction pin and comfirm transaction pin does not match");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;
    

        }else{


            setErrMsg("");

            // starts here 
            const checkData1 = localStorage.getItem('tokenID');
            const checkData2 = localStorage.getItem('currentUserName');
            
            if(checkData1 && checkData2){
                let urls = "http://127.0.0.1:8000/api/changeuserpinapi";
            
                async function proceedToSendDataToServer (){
                    try{
                        const registerPostFeedBack = await axios.post(urls,{
            
                            tokenCode: checkData1,
                            userName: checkData2,

                            oldPin: oldPin,
                            newPin: newPin,
                           
                           

            
                        }).then((res) => {
            
                            let feedbackMsg = res.data.msg;
                            let feedbackStatusCode = res.data.status;
            
            
                            if(feedbackStatusCode == 200){
                                toast.success(feedbackMsg);
                                
                                setMsgLabel("Transaction Pin Reset");
                                setMsgPara("Transaction Pin reset  was successful, your transaction pin has been updated.");
                                setTimeout(() => {
                                    $("#showModalContainerMsg").show();
                                  }, 1000);
                              
                            }

            
                            if(feedbackStatusCode == 402){
                                toast.error(feedbackMsg);
                                setErrMsg(feedbackMsg);
                                $("#errMsg").show();
                                $("#depositProceedButton").show();
                                $("#processingButton").hide();
                            }
            
                            if(feedbackStatusCode == 401){
                                navigateRoutes('/login', { replace: true });
                            }
            
                            if(feedbackStatusCode == 501){
                                navigateRoutes('/login', { replace: true });
                            }
            
                            
            
                        });
            
                   }catch (err){
            
                    toast.error("Error connecting to the servers.");
                    setErrMsg("Error connecting to the servers.");
                    $("#depositProceedButton").show();
                    $("#processingButton").hide();
             
                    //  console.log(err);
            
                 }
            
            
                }
                proceedToSendDataToServer();
            
            
            }else{
            
            navigateRoutes('/login', { replace: true });
            }
            
            

        }


    
    
    }











    
    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />



        {/* main */}

            <main className="mainDashContent">

            <ToastContainer />  
 

                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                        {/* box 1 */}
                                   


                                        
 
                                        {/* box 2 */}

                                                                                
                                        <div className="depositMainContainer">
                                            <div className="depositMainContainer_Sub" style={{ 
                                                height: "auto",
                                                
                                             }}>
                                                    {/* <h4>Profile Details</h4> */}

                                                    {/* <Link to='/dashsettings' style={{ color:"#fff" }}>
                                                            <p>
                                                            <i class="bi bi-arrow-left-square-fill"></i> Go Back 
                                                            </p>
                                                    </Link> */}
                                                   

                                                    <ul class="nav nav-tabs nav-justified" role="tablist">

                                                        <li class="nav-item " role="presentation" >
                                                            <a class="nav-link active settingsTabs" id="justified-tab-0" data-bs-toggle="tab" href="#justified-tabpanel-0" role="tab" aria-controls="justified-tabpanel-0" aria-selected="true"> <span style={{ color: "#fff"}}> Reset Your Password </span> </a>
                                                        </li>
                                                        <li class="nav-item" role="presentation">
                                                            <a class="nav-link settingsTabs" id="justified-tab-1" data-bs-toggle="tab" href="#justified-tabpanel-1" role="tab" aria-controls="justified-tabpanel-1" aria-selected="false"> <span style={{ color: "#fff"}}>  Reset Transaction Pin  </span> </a>
                                                        </li>
                                                      
                                                    </ul>

                                                            
                                                <div class="tab-content pt-5" id="tab-content">
                                                    <p style={{ textAlign:"center", color: "red" }}>
                                                        {errMsg}
                                                    </p>
                                                    {/* reset password tab */}
                                                <div class="tab-pane active" id="justified-tabpanel-0" role="tabpanel" aria-labelledby="justified-tab-0">
                                                <h4 style={{ color:"#fff" }}>Reset Account Password</h4>
                                                   
                                                        <form>
                                                        <label className='depositAmountContainer_2_Labelzs' style={{ fontSize: 13, }}> Enter Old Password </label>
                                                            <div className="depositAmountContainer">
                                                                
                                                                <div className="depositAmountContainer_2">
                                                                <input type="text" placeholder="Enter Old Password" onChange={(e) => setoldPass(e.target.value)}  className='DepositformInputAmount'  />
                                                                </div>
                                                            </div>
                                                            <br /> 

                                                            <label className='depositAmountContainer_2_Labelzs' style={{ fontSize: 13, }}> Enter New Password </label>
                                                            <div className="depositAmountContainer">
                                                                
                                                                <div className="depositAmountContainer_2">
                                                                <input type="text" placeholder="Enter New Password" onChange={(e) => setnewPass(e.target.value)}  className='DepositformInputAmount' />
                                                                </div>
                                                            </div>
                                                            <br /> 

                                                        
                                                            <label className='depositAmountContainer_2_Labelzs' style={{ fontSize: 13, }}> Confirm New Password </label>
                                                            <div className="depositAmountContainer">
                                                                
                                                                <div className="depositAmountContainer_2">
                                                                <input type="text" placeholder="Confirm New Password" onChange={(e) => setcnewPass(e.target.value)}  className='DepositformInputAmount' />
                                                                </div>
                                                            </div>
                                                            <br /> 

                                        

                                                        {/* submit button */}

                                                        <button className="depositProceedButton" id="depositProceedButton" onClick={confirmRequest}>RESET PASSWORD </button>
                                                        <button id="processingButton" className="cmn-btn w-100 buttForm"  disabled> 
                                                    <div class="spinner-border" role="status" style={{ width: 18, height:18 }}>
                                                        <span class="visually-hidden" >Loading...</span>
                                                    </div>
                                                    PROCESSING...
                                                </button>
                                                    </form>
                                                    
                                                </div>









                                                <div class="tab-pane" id="justified-tabpanel-1" role="tabpanel" aria-labelledby="justified-tab-1">
                                                     {/* reset otp code tab */}

                                                     <h4 style={{ color:"#fff" }}>Reset Transaction Pin</h4>

                                                <form>
                                                <label className='depositAmountContainer_2_Labelzs' style={{ fontSize: 13, }}> Enter Old Transaction Pin </label>
                                                            <div className="depositAmountContainer">
                                                                
                                                                <div className="depositAmountContainer_2">
                                                                <input type="number" onChange={(e) => setoldPin(e.target.value)}  placeholder="Enter Old Transaction Pin"   className='DepositformInputAmount'  />
                                                                </div>
                                                            </div>
                                                            <br /> 

                                                             <label className='depositAmountContainer_2_Labelzs' style={{ fontSize: 13, }}> Enter New Transaction Pin </label>
                                                            <div className="depositAmountContainer">
                                                                
                                                                <div className="depositAmountContainer_2">
                                                                <input type="number" onChange={(e) => setnewPin(e.target.value)}  placeholder="Enter New Transaction Pin"   className='DepositformInputAmount' />
                                                                </div>
                                                            </div>
                                                            <br /> 

                                                        
                                                             <label className='depositAmountContainer_2_Labelzs' style={{ fontSize: 13, }}> Confirm New Transaction Pin </label>
                                                            <div className="depositAmountContainer">
                                                                
                                                                <div className="depositAmountContainer_2">
                                                                <input type="number" onChange={(e) => setcnewPin(e.target.value)}  placeholder="Confirm New Transaction Pin"   className='DepositformInputAmount' />
                                                                </div>
                                                            </div>
                                                            <br /> 

                                        

                                                        {/* submit button */}

                                                        {/* <button className="depositProceedButton">Reset Transaction Pin </button> */}
                                                        <button className="depositProceedButton" id="depositProceedButton" onClick={confirmPinRequest}>RESET TRANSACTION PIN </button>
                                                        <button id="processingButton" className="cmn-btn w-100 buttForm"  disabled> 
                                                    <div class="spinner-border" role="status" style={{ width: 18, height:18 }}>
                                                        <span class="visually-hidden" >Loading...</span>
                                                    </div>
                                                    PROCESSING...
                                                </button>
                                                    </form>

                                                </div>

                                                       
                                                




                                                </div>


                                        
                                                
                                                    
                                            </div>

                                              
                                        </div>




















 {/* start overlay modal */}
 <div className="modalOverlay" id="showModalContainerMsg">
                                                            <div className="modalOverlayContainer" style={{  height: "auto", padding:40, }}>

                                            <center>
                                                {/* succImg */}
                                                <img src={suc1} width='60' style={{paddingTop: 30}} />
                                                {/* <img src={errimg} width='70' style={{paddingTop: 30}} /> */}

                                                <h3> {msgLabel} </h3>

                                                {/* <h3 style={{color: "red"}}>Error </h3> */}
                                                
                                                <div className="modalPara">
                                                    <p>
                                                        {msgPara}
                                                    </p>

                                                  

                                                </div>
                                               


                                                <div className="modalButtons"> 

                                                    {/* <div className="confirmModalButton">
                                                        <p>
                                                            Confirm
                                                        </p>
                                                    </div> */}

                                                    {/* cancelModalButton */}
                                                    <div className="confirmModalButton" onClick={hideModalContainerMsg}>
                                                        <p>
                                                            OK
                                                        </p>
                                                    </div>

                                                </div>

                                            </center>
                                        

                                        </div>
                                    </div>
                                    {/* end overlay modal */}






                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={set1img} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}