// BusinessAccount













import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function BusinessAccount ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                   <li> <Link to='/businessbanking'> Business Banking </Link> </li>
                                   {/* <li> <Link to='/businessaccount'> Business Account </Link> </li> */}
                                    <li> <Link to='/tradefinance'> Trade Finance </Link> </li>
                                    
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Business Account </h2>

            
                                <p style={{ color: "#333", }}>
                                Whether you are looking to open a Business Account in the USA or wish to move your existing Business Account to USA , we will offer you all the support you need.


                                </p>
                                <p style={{ color: "#333", }}>
                                From Trade Finance, Escrow management and Online Banking to Treasury services we have a range of products and services to meet the needs of your business.

                                </p>


                                <h2> Why choose our Business Account? </h2>
                                <p style={{ color: "#333", }}>
                                    1. You will have your own dedicated Relationship Manager who will guide you and help you with all your needs.

                                </p>
                                <p style={{ color: "#333", }}>
                                    2. We offer same-day transfers between your USA Account to accounts held with Arbitrage Credit Union subsidiaries.


                                </p>
                                <p style={{ color: "#333", }}>
                                    3. You can choose to open a US Dollar, GBP or Euro current account to facilitate your international transactions.

                                </p>
                                <p style={{ color: "#333", }}>
                                    4. You will be offered a cheque book and paying-in book for your account.

                                </p>
                                <p style={{ color: "#333", }}>
                                    5. You will be able to control your finances any day, anytime though our quick and easy Online banking service.

                                </p>
                                <p style={{ color: "#333", }}>
                                    6. You will be able to set up Standing Orders and Direct Debits with our simple and straightforward internet banking.

                                </p>
                                <p style={{ color: "#333", }}>
                                    7. We will send your monthly statements to your chosen correspondence address.

                                </p>

                                

                               

                               
                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>
                               


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}