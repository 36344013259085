// LoansCredit





import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function LoansCredit ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                  
                                   {/* <li> <Link to='/loanscredit'> Loans & Credit </Link> </li> */}
                                    <li> <Link to='/mortgageloans'> Mortgage Loans </Link> </li>
                                    <li> <Link to='/propertyloans'> Property Loans </Link> </li>
                                    
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Loans & Credit </h2>

            
                                <p style={{ color: "#333", }}>
                                When your business needs funds to grow, Arbitrage Credit Union has the answer. We offer several business loan options to help meet your particular financial situation. Whether you require a one-time disbursement of funds for general growth, or if your need is specific to equipment or real estate, we have the solution.



                                </p>
                                <p style={{ color: "#333", }}>
                                We offer a choice of competitive rates, market-leading systems and top-quality service, designed to offer you more choice.



                                </p>


                                <p style={{ color: "#333", }}>
                                We pride ourselves as being a bank that welcomes customers from all locations and origins with the promise that you will be treated fairly, consistently and professionally, with individual service being an absolute priority.




                                </p>
                                {/* <p style={{ color: "#333", }}>
                                We are committed to providing you with an exclusive and bespoke excellent level service by working with you to build a long-term relationship based on understanding, knowledge, trust and accountability.



                                </p>
                                <p style={{ color: "#333", }}>
                                We assure you of our complete discretion when dealing with your financial affairs. Confidentiality and discretion are at the very core of our business. Our operations are authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and Prudential Regulation Authority, Data Protection Regulations and the Banking Act. This ensures that your personal information is held securely and confidentially at all times.



                                </p> */}


                                {/* <h2> Why choose our Business Account? </h2>
                                <p style={{ color: "#333", }}>
                                    1. You will have your own dedicated Relationship Manager who will guide you and help you with all your needs.

                                </p>
                                <p style={{ color: "#333", }}>
                                    2. We offer same-day transfers between your USA Account to accounts held with Arbitrage Credit Union subsidiaries.


                                </p>
                                <p style={{ color: "#333", }}>
                                    3. You can choose to open a US Dollar, GBP or Euro current account to facilitate your international transactions.

                                </p>
                                <p style={{ color: "#333", }}>
                                    4. You will be offered a cheque book and paying-in book for your account.

                                </p>
                                <p style={{ color: "#333", }}>
                                    5. You will be able to control your finances any day, anytime though our quick and easy Online banking service.

                                </p>
                                <p style={{ color: "#333", }}>
                                    6. You will be able to set up Standing Orders and Direct Debits with our simple and straightforward internet banking.

                                </p>
                                <p style={{ color: "#333", }}>
                                    7. We will send your monthly statements to your chosen correspondence address.

                                </p> */}

                                

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}