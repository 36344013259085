// Thirdcode










import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";


import {useState} from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";

import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';




// import dash images
import withimg from "../DashImg/withimg.svg";

export default function Thirdcode(){
    const navigateRoutes = useNavigate();

    const [codeProvidedByUser, setCodeProvidedByUser] = useState("");
    const [errMsg, setErrMsg] = useState("");



    const hideModalContainerMsg = () => {
        $("#showModalContainerMsg").hide();
    }

    const showModalConatinMsg = () => {
        $("#showModalContainerMsg").show();
    }




    const confirmRequest = (e) => {
        e.preventDefault();
        $("#depositProceedButton").hide();
        $("#processingButton").show();


        if(codeProvidedByUser == ""){
            toast.error("Provide COT Code");
            setErrMsg("Provide COT Code");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if(codeProvidedByUser.length < 6 || codeProvidedByUser.length > 6){
            toast.error("Provide a valid COT Code");
            setErrMsg("Provide a valid COT Code");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else{

            // starts here 
            const checkData1 = localStorage.getItem('tokenID');
            const checkData2 = localStorage.getItem('currentUserName');

            if(checkData1 && checkData2){
                let urls = "http://127.0.0.1:8000/api/confirmtransfercodes";

                async function proceedToSendDataToServer (){

                    try{
                        const registerPostFeedBack = await axios.post(urls, {
                            tokenCode: checkData1,
                            userName: checkData2,
                            codeProvidedByUser: codeProvidedByUser,
                            codeType: "COT",

                        }).then((res) => {
                            let feedbackMsg = res.data.msg;
                            let feedbackStatusCode = res.data.status;


                            if(feedbackStatusCode == 200){
                                toast.success(feedbackMsg);
                            
                                setTimeout(() => {
                                    navigateRoutes('/confirmtransaction');
                                  }, 2000);  
                                
                               
                            }

                            if(feedbackStatusCode == 402){
                                toast.error(feedbackMsg);
                                errMsg(feedbackMsg);
                                $("#depositProceedButton").show();
                                $("#processingButton").hide();
                            }
    
                            if(feedbackStatusCode == 403){
                                toast.error(feedbackMsg);
                                $("#depositProceedButton").show();
                                $("#processingButton").hide();
                            }
    
                            if(feedbackStatusCode == 401){
                                navigateRoutes('/login', { replace: true });
                            }
    
                            if(feedbackStatusCode == 501){
                                navigateRoutes('/login', { replace: true });
                            }


                        } );



                    }catch (err){
 
                        toast.error("Error connecting to the server.");
                        setErrMsg("Error connecting to the server.");
                        $("#depositProceedButton").show();
                        $("#processingButton").hide();
             
                        //  console.log(err);
 
                 }



                }

                proceedToSendDataToServer ();

            }else{
                navigateRoutes('/login', { replace: true });
            }




        }











    }








    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                       



                                        {/* box 2 */}

                                                                                
                                        <div className="depositMainContainer">
                                            <div className="depositMainContainer_Sub" style={{ height: "auto" }}>
                                                
                                                {/* progress bar1 */}
                                            <div className="progressContainerForCode">
                                                <div className="progressBar_3"></div>
                                            </div>
                                            <ToastContainer />  
                                            <form>
                                               
                                                    <h3 className="codeLabel">COT Code  </h3>


                                                 {/* <label htmlFor="fullname" className='depositFormLabel'>Enter Bank Routing Number </label> <br /> */}
                                                        <p style={{ color: "red" }}> {errMsg} </p>
                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="number" placeholder="Enter your COT code."  onChange={(e) => setCodeProvidedByUser(e.target.value)}  className='DepositformInputAmount' />
                                                        
                                                        </div>
                                                    </div>
                                                    <p style={{ color: "red", padding:10, cursor: "pointer", fontSize:14, }} onClick={showModalConatinMsg}>What is a COT code? click here to learn more</p>

                                                
                                                
                                            

                                                {/* submit button */}

                                                {/* <button className="depositProceedButton"><i class="bi bi-send-check"></i> Proceed </button> */}
                                                <button className="depositProceedButton" id="depositProceedButton" onClick={confirmRequest}><i class="bi bi-send-check"></i> Proceed </button>
                                                <button id="processingButton" className="cmn-btn w-100 buttForm"  disabled> 
                                                    <div class="spinner-border" role="status" style={{ width: 18, height:18 }}>
                                                        <span class="visually-hidden" >Loading...</span>
                                                    </div>
                                                    PROCESSING...
                                                </button>
                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>



















                            {/* start overlay modal */}
                            <div className="modalOverlay" id="showModalContainerMsg">
                                        <div className="modalOverlayContainer" style={{  height: "auto", padding:40, }}>

                                            <center>
                                                {/* succImg */}
                                                {/* <img src={suc1} width='60' style={{paddingTop: 30}} /> */}
                                                {/* <img src={errimg} width='70' style={{paddingTop: 30}} /> */}

                                                <h3> </h3>

                                                <h3 >What is COT Code? </h3>
                                                
                                                <div className="modalPara">
                                                    <p>
                                                    COT ( COST OF TRANSFER ), this is needed to complete a transfer in many circumstances, especially when you're on a high priority account known as Business Gold. You should contact us for assistance in any transfers of money. A Cost of Transfer is needed to complete international wire transfers.

                                                    </p>

                                                    <p style={{ color: "red" }}>
                                                        If you unable to find your COT code, please contact your account manager or our customer live
                                                        chat support for quick enquiries.
                                                    </p>

                                                </div>
                                               


                                                <div className="modalButtons"> 

                                                    <div className="confirmModalButton" onClick={hideModalContainerMsg}>
                                                        <p>
                                                            OK
                                                        </p>
                                                    </div>
                                                    {/* <div className="cancelModalButton" onClick={hideModalContainerMsg}>
                                                        <p>
                                                            OK
                                                        </p>
                                                    </div> */}

                                                </div>

                                            </center>
                                        

                                        </div>
                                    </div>
                                    {/* end overlay modal */}




                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={withimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}