// DashKyc



import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";



// import dash images
import kycimg from "../DashImg/kycimg.svg";

export default function DashKyc(){

    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                        {/* box 1 */}
                                        {/* <div className="mainContent_1_Container_box_1">
                                            <div className="mainContent_1_Container_box_1_Content">
                                                <h2>Total Referral Bonus</h2>

                                                <p className="">$50.00</p>

                                                <small className="mainContent_1_Container_box_1_Content_small">0.00134 BTC</small>
                                            </div>

                                        </div> */}



                                        {/* box 2 */}

                                                                                
                                        <div className="kycMainContainer" >
                                            <div className="kycMainContainer_Sub" >
                                                <h3>Upload Your KYC</h3>
                                                <br />
                                            <form>
                                               

                                               

                                                
                                                {/* <label htmlFor="fullname" className='depositFormLabel'>Your Referral Link. </label> <br /> */}
                                                
                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Enter Your Country"   className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                <br /> 


                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Enter Your State"   className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                <br /> 
                                                
                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Enter Your City"   className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                <br /> 
                                                
                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Enter Your Occupation"   className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                <br /> 
                                                
                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Enter Your Phone Number"   className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                <br /> 

                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Enter Your House Address"   className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                <br /> 
                                                
                                                
                                                <label htmlFor="fullname" className='depositFormLabel'>Upload ID Card </label> <br />
                                                    <div className="depositAmountContainer">
                                                        {/* <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div> */}
                                                        <div className="depositAmountContainer_2">
                                                        <input type="file"  style={{ color: "white" }}   className='DepositformInputAmount' />
                                                        </div>
                                                    </div>
                                                <br /> 
                                                


                                                {/* submit button */}

                                                <button className="depositProceedButton">Upload KYC </button>

                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>
























                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={kycimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}