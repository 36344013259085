

import $ from "jquery";
import {Link } from "react-router-dom";

import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import logo from "../images/logo.png";


export default function DashHead(){

    const [fullName, setFullName] = useState("");


    const shownotificationDetailsCard_1 = () =>{
        $("#viewTransactions").toggle(); 
    }

    const shownotificationDetailsCard_2 = () =>{
        $("#viewNotifications").toggle(); 
    }


    const showMobileNav = () =>{
        $("#modalOverlayMobileNav").show(); 
    }

    const hideMobileNav = () =>{
        $("#modalOverlayMobileNav").hide(); 
    }

    
    

    const navigateRoutes = useNavigate();


    useEffect(() => {

        const checkData1 = localStorage.getItem('acctnameaa');
        
       if(checkData1){

        setFullName(checkData1);
        
       }else{
        
        navigateRoutes('/login', { replace: true });

       }
       

    }, []);



    const userLogout = () => {

        localStorage.clear();
        Cookies.remove("isUserOnline");
        navigateRoutes('/login', { replace: true });

    }
    






    return(


        
        <>
        
        

                {/* header */}
                <div className="headerContainer">
                    {/* search bar side */}
                    
                    <div className="headerContainer_Sub_1">

                        <div className="dashMobileLogo">
                            {/* <Link to='/dashboard'> 
                                <h2 className="dashMobileLogoH" style={{ color:"#092980", fontWeight: "bold", }}>Bank Logo</h2>
                            </Link> */}

                        <Link to="/dashboard">
                         <img src={logo} width={100} style={{ 
                                            position:"relative",
                                            top: -15,
                                         }}  />
                         </Link>
                            
                        </div>
                      

                        <div className="otherHeaderContainerSub_1_Sub">

                            {/* <form className="d-flex" role="search">
                                <input className="form-control me-2" type="search" placeholder="Search For An Investor" aria-label="Search" />
                                <button className="btn " type="submit">Search</button>
                            </form> */}

                            <p className="headerNameLabel">Welcome, {fullName}</p>

                        </div>

                    </div>



                    <div className="headerContainer_Sub_2">


                    <div className="otherHeaderContainerSub_2">
                                    <div className="otherHeaderContainerSub_2_sub">

                                        {/* active investment  icon*/}
                                        {/* <Link to='/dashgroupmsg'>
                                            <div className="otherHeaderContainerSub_2_sub_Card">
                                                <i class="bi bi-chat-dots-fill walletIcon viewTransactions"  onClick={shownotificationDetailsCard_1}></i>
                                                
                                            </div>
                                        </Link> */}

                                            {/* notification icon */}
                                        <div className="otherHeaderContainerSub_2_sub_Card">
                                         {/* <i class="bi bi-bell walletIcon" onClick={shownotificationDetailsCard_2}></i> */}


                                         {/* notification details box */}
                                         <div class="card notificationDetailsCard_1" id="viewNotifications" style={{display: "none",}}>
                                                    <div class="card-body">

                                                        <div className="notificationDetailsCard_1_Headings">
                                                            <h4>Recent Notifications:</h4>
                                                        </div>

                                                            {/* begins loops here */}
                                                        <div className="notificationDetailsCard_1_Card_Details">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Deposit</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500.</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Withdrawal</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500.</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Referral Bonus</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Investment</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500.</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>
                                                        {/* end loops here */}


                                                        <div className="notificationFooter">
                                                            <Link to="/dashnotification">
                                                                 <p>view all notifications </p>
                                                           </Link>
                                                           
                                                        </div>

                                                    </div>
                                                </div>

                                                 {/* notification details box */}



                                        </div>

                                            {/* profile icon */}
                                            <Link to='/dashprofile'>
                                           
                                                <div className="otherHeaderContainerSub_2_sub_Card ">
                                                     <i class="bi bi-person-circle walletIcon" ></i>
                                                </div>
                                            </Link>
                                       

                                            {/* settings icon */}
                                        <Link to='/dashsettings' className="hideSmallestScreen">
                                            <div className="otherHeaderContainerSub_2_sub_Card ">
                                                <i class="bi bi-gear walletIcon"></i>
                                            </div>
                                        </Link>

                                        {/* logout icons */}
                                        <div className="otherHeaderContainerSub_2_sub_Card noMobile" onClick={userLogout}>
                                            <i class="bi bi-box-arrow-right walletIcon"></i>
                                        </div>
                                        {/* <i class="bi bi-list"></i> */}

                                        <div className="otherHeaderContainerSub_2_sub_Card showMobile" onClick={showMobileNav}>
                                            <i class="bi bi-list walletIcon"></i>
                                        </div>

                                        <p className="clearx"></p>
                                    </div>
                                </div>









                                            {/* nav for mobile app */}
                                 {/* start overlay modal */}
                                 <div className="modalOverlayMobileNav" id="modalOverlayMobileNav">
                                     <div className="closeMobileNavcontainer">
                                            <div className="closeMobileNavcontainer_1"></div>

                                            <div className="closeMobileNavcontainer_2" onClick={hideMobileNav}>
                                                <p>Close</p>
                                            </div>
                                     </div>


                                     {/* nav content */}
                                     <div className="mobileNavContxx">
                                     <div className="navContainer">
                    <nav>
                        <ul>

                        <Link to="/dashboard" onClick={hideMobileNav}>
                            <li>
                                <Link to="/dashboard">
                                    <i class="bi bi-house"></i>
                                    <span className="navText">Dashboard</span>
                                </Link>
                               
                            </li>
                            </Link>

                            {/* <Link to="/dashasset" onClick={hideMobileNav}>
                            <li>
                                <Link to="/dashasset">
                                <i class="bi bi-wallet2"></i>
                                    <span className="navText">Assets</span>
                               </Link>
                               
                            </li>
                            </Link> */}
                           

                           
                            <Link to="/dashdeposit" onClick={hideMobileNav}>
                            <li>
                                <Link to="/dashdeposit">
                                <i class="bi bi-cash"></i>
                                    <span className="navText">Deposit Funds</span>
                               </Link>
                               </li>
                            </Link>



                            <Link to="/dashwithdraw" onClick={hideMobileNav}>
                            <li>
                                <Link to="/dashwithdraw">
                                <i class="bi bi-cash-coin"></i>
                                    <span className="navText">Transfer Funds</span>
                               </Link>
                               
                            </li>
                            </Link>



                           <Link to="/withdrawviacrypto" onClick={hideMobileNav}>
                           <li>
                                <Link to="/withdrawviacrypto">
                                <i class="bi bi-file-earmark-arrow-up"></i>
                                    <span className="navText">Withdraw Via Crypto</span>
                               </Link>
                               
                            </li>
                            </Link>
                           {/* <Link to="/dashreferral" onClick={hideMobileNav}>
                            <li>
                                <Link to="/dashreferral">
                                <i class="bi bi-people"></i>
                                    <span className="navText">Referral</span>
                               </Link>
                               
                            </li>
                            </Link> */}
                           
                            

                            {/* <Link to="/dashkyc" onClick={hideMobileNav}>
                            <li>
                                <Link to="/dashkyc">
                                <i class="bi bi-file-earmark-arrow-up"></i>
                                    <span className="navText">KYC</span>
                               </Link>
                               
                            </li>
                            </Link> */}
                           


                            <Link to="/dashcard" onClick={hideMobileNav}>
                             <li>
                                <Link to="/dashcard">
                                <i class="bi bi-credit-card"></i>
                                    <span className="navText">Debit Card</span>
                               </Link>
                               </li>
                            </Link>
                           


                            {/* <Link to="/dashtransaction" onClick={hideMobileNav}>
                            <li>
                                <Link to="/dashtransaction">
                                <i class="bi bi-clock-history"></i>
                                    <span className="navText">Transactions</span>
                               </Link>
                               
                            </li>
                            </Link> */}



                           {/* <Link to="/dashactivetrades" onClick={hideMobileNav}>
                            <li>
                                <Link to="/dashactivetrades">
                                <i class="bi bi-activity"></i>
                                    <span className="navText">Active Trades</span>
                               </Link>
                               
                            </li>
                            </Link> */}



                            {/* view for device  */}

                           <Link to="/dashsettings" onClick={hideMobileNav} className="showSmallestScreen">
                           <li>
                                <Link to="/dashsettings">
                                {/* <i class="bi bi-clock-history"></i> */}
                                <i class="bi bi-gear "></i>
                                    <span className="navText">Settings</span>
                               </Link>
                               
                            </li>
                            </Link>
                            
                           <Link to="/dashprofile" onClick={hideMobileNav} className="showSmallestScreen">
                           <li>
                                <Link to="/dashprofile">
                               
                                <i class="bi bi-person-circle " ></i>
                                    <span className="navText">Profile</span>
                               </Link>
                               
                            </li>
                            </Link>

                           <Link to="/applyloan" onClick={hideMobileNav} className="showSmallestScreen">
                           <li>
                                <Link to="/applyloan">
                               
                                <i class="bi bi-activity"></i>
                                    <span className="navText">Loan</span>
                               </Link>
                               
                            </li>
                            </Link>


                           
                            <li className="logoutUser" onClick={userLogout} style={{backgroundColor: "red", border: "none", color: "white", }} >
                                <Link to="/dashboard">
                                <i class="bi bi-box-arrow-right" style={{ color:"white", }}></i>
                                    <span className="navText" style={{ color:"white", }}>Logout</span>
                               </Link>
                               
                            </li>
                           




                        </ul>

                    </nav>

                </div>



                                     </div>



                                      

                                
                                </div>
                                    {/* end overlay modal */}





                    </div>



                 <p className="clearx"></p>
                </div>


        
        
        
        
        </>

    );




}