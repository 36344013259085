// ConfirmTransaction







import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";


import {useState, useEffect} from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";

import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';



// import dash images
import withimg from "../DashImg/withimg.svg";

export default function ConfirmTransaction(){

    const navigateRoutes = useNavigate();

    const [receiverAcctName, setReceiverAcctName] = useState("");
    const [receiverAcctNumber, setReceiverAcctNumber] = useState("");
    const [receiverBankName, setReceiverBankName] = useState("");
    const [sendingAmount, setSendingAmount] = useState("");
    const [sendingPurpose, setSendingPurpose] = useState("");
    const [dateOfTransaction, setdateOfTransaction] = useState("");
    
    const [senderAcctName, setsenderAcctName] = useState("");
    const [senderAcctNumb, setsenderAcctNumb] = useState("");
    const [transferType, setTransferType] = useState("");


    const [transactionPin, setTransactionPin] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [errMsgx, setErrMsgx] = useState("");



    useEffect(() => {


        const receiverAcctNamea = Cookies.get('receiverAcctName');
        // const checkIfUserTIsLogin = Cookies.get('isUserOnline');
        const receiverAcctNumbera = Cookies.get('receiverAcctNumber');
        const receiverBankNamea = Cookies.get('receiverBankName');
        const sendingAmounta = Cookies.get('sendingAmount');
        const sendingPurposea = Cookies.get('sendingPurpose');
        const transferType = Cookies.get('transferType');

        const senderAcctName = localStorage.getItem('acctnameaa');
        const senderAcctNumb = localStorage.getItem('acctnum');
        


        
        if(receiverAcctNamea && receiverAcctNumbera && receiverBankNamea && sendingAmounta && sendingPurposea && senderAcctName && senderAcctNumb){

            let newDate = new Date()
            let date_raw = newDate.getDate();
            let month_raw = newDate.getMonth() + 1;
            let year = newDate.getFullYear();
            let finalDate = date_raw + "/" + month_raw + "/" + year;
            setdateOfTransaction(finalDate);
            

            // set
            setReceiverAcctName(receiverAcctNamea);
            setReceiverAcctNumber(receiverAcctNumbera);
            setReceiverBankName(receiverBankNamea);
            setSendingAmount(sendingAmounta);
            setSendingPurpose(sendingPurposea);
            setTransferType(transferType);

            setsenderAcctName(senderAcctName);
            setsenderAcctNumb(senderAcctNumb);


            // console.log(receiverAcctNumbera);

         
        }else{
         
            // console.log("nah1");
         navigateRoutes('/thirdsecuritycode',);
 
        }


        
 
 
     }, []);
 






     const confirmRequest = (e) => {
        e.preventDefault();

        $("#depositProceedButton").hide();
        $("#processingButton").show();

        if(transactionPin == ""){
            toast.error("Enter Transaction Pin");
            setErrMsg("Enter Transaction Pin");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if(transactionPin.length < 4 || transactionPin.length > 4){
            toast.error("Invalid Transaction Pin");
            setErrMsg("Invalid Transaction Pin");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if(isNaN(transactionPin)){
            toast.error("Invalid Transaction Pin");
            setErrMsg("Invalid Transaction Pin");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else{


             // starts here 
             const checkData1 = localStorage.getItem('tokenID');
             const checkData2 = localStorage.getItem('currentUserName');
 
             if(checkData1 && checkData2){
                let urls = "http://127.0.0.1:8000/api/confirmtransferapi";

                async function proceedToSendDataToServer (){

                    try{
                        const registerPostFeedBack = await axios.post(urls, {
                            tokenCode: checkData1,
                            userName: checkData2,
                            codeProvidedByUser:transactionPin,

                            sendingAmount:sendingAmount,
                            receiverAcctName:receiverAcctName,
                            receiverAcctNumber:receiverAcctNumber,
                            receiverBankName:receiverBankName,
                            sendingPurpose:sendingPurpose,
                            transferType:transferType,
                            

                        }).then((res) => {
                            let feedbackMsg = res.data.msg;
                            let feedbackStatusCode = res.data.status;
                            let currentAcctBal = res.data.newAcctbal;
                            let transactionID = res.data.transactID;


                            if(feedbackStatusCode == 200){
                                toast.success(feedbackMsg);

                                // update new acct balance
                                localStorage.setItem('acctbalaa', currentAcctBal);
                                Cookies.set('currenttransactionID', transactionID, { expires: 1 });
                                
                            
                                setTimeout(() => {
                                    navigateRoutes('/transactionsuccessful');
                                  }, 2000);  
                                
                                // $("#depositProceedButton").show();
                                // $("#processingButton").hide();
                            }

                            if(feedbackStatusCode == 402){
                                toast.error(feedbackMsg);
                                errMsg(feedbackMsg);
                                $("#depositProceedButton").show();
                                $("#processingButton").hide();
                            }
    
                            if(feedbackStatusCode == 403){
                                toast.error(feedbackMsg);
                                setErrMsgx(feedbackMsg);
                                $("#depositProceedButton").show();
                                $("#processingButton").hide();
                            }

                            if(feedbackStatusCode == 404){
                                toast.error(feedbackMsg);
                                setErrMsgx(feedbackMsg);
                                $("#depositProceedButton").show();
                                $("#processingButton").hide();
                            }
    
                            if(feedbackStatusCode == 401){
                                // toast.error(feedbackMsg);
                                // $("#depositProceedButton").show();
                                // $("#processingButton").hide();
                                navigateRoutes('/login', { replace: true });
                            }
    
                            if(feedbackStatusCode == 501){
                                toast.error(feedbackMsg);
                                $("#depositProceedButton").show();
                                $("#processingButton").hide();
                                // navigateRoutes('/login', { replace: true });
                            }


                        } );



                    }catch (err){
 
                        toast.error("Error connecting to the server.");
                        setErrMsg("Error connecting to the server.");
                        $("#depositProceedButton").show();
                        $("#processingButton").hide();
             
                        //  console.log(err);
 
                 }



                }

                proceedToSendDataToServer ();

            }else{
                navigateRoutes('/login', { replace: true });
            }











        }

        
    
    
    
    
    }



       







    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                       



                                        {/* box 2 */}
                                        <ToastContainer />  
                                                                                
                                        <div className="depositMainContainer">
                                            <div className="depositMainContainer_Sub" style={{ height: "auto" }}>
                                                
                                                {/* progress bar1 */}
                                            <div className="progressContainerForCode">
                                                <div className="progressBar_ConfirmTransfer"></div>
                                            </div>

                                            <form>
                                               
                                                    <h3 className="codeLabel">Confirm Transfer</h3>

                                                    
                                                    <div className="confirmMainContainer">
                                                        <br />
                                                        

                                                        {/* start */}
                                                        <div className="confirmMainContainer_Sub" style={{ height: "auto", padding:9, }}>
                                                            <div className="confirmMainContainer_Sub_1">
                                                                <p>From: </p>
                                                            </div>
                                                            <div className="confirmMainContainer_Sub_2">
                                                                <p>{senderAcctName} {senderAcctNumb}</p>
                                                            </div>
                                                        </div>

                                                        <div className="confirmMainContainer_Sub" style={{ height: "auto", padding:9, }}>
                                                            <div className="confirmMainContainer_Sub_1">
                                                                <p>To: </p>
                                                            </div>
                                                            <div className="confirmMainContainer_Sub_2">
                                                                <p>{receiverAcctName} {receiverAcctNumber} </p>
                                                            </div>
                                                        </div>

                                                        <div className="confirmMainContainer_Sub" style={{ height: "auto", padding:9, }}>
                                                            <div className="confirmMainContainer_Sub_1">
                                                                <p>Bank: </p>
                                                            </div>
                                                            <div className="confirmMainContainer_Sub_2">
                                                                <p>{receiverBankName}</p>
                                                            </div>
                                                        </div>

                                                        <div className="confirmMainContainer_Sub" style={{ height: "auto", padding:9, }}>
                                                            <div className="confirmMainContainer_Sub_1">
                                                                <p>Date: </p>
                                                            </div>
                                                            <div className="confirmMainContainer_Sub_2">
                                                                <p>{dateOfTransaction}</p>
                                                            </div>
                                                        </div>

                                                        <div className="confirmMainContainer_Sub" style={{ height: "auto", padding:9, }}>
                                                            <div className="confirmMainContainer_Sub_1">
                                                                <p>Amount: </p>
                                                            </div>
                                                            <div className="confirmMainContainer_Sub_2">
                                                                <p>${sendingAmount}</p>
                                                            </div>
                                                        </div>

                                                        <div className="confirmMainContainer_Sub" style={{ height: "auto", padding:9, }}>
                                                            <div className="confirmMainContainer_Sub_1">
                                                                <p>Narration: </p>
                                                            </div>
                                                            <div className="confirmMainContainer_Sub_2">
                                                                <p>{sendingPurpose}</p>
                                                            </div>
                                                        </div>


                                                        {/* end */}
                                                        <form>

                                                        <div className="confirmMainContainer_Sub" style={{ border: "none", }}>
                                                           
                                                            <div className="confirmMainContainer_Sub_22">
                                                                <center>
                                                                    <p style={{ color:"red" }}>
                                                                    {errMsgx}
                                                                    </p>
                                                                    <h3>Enter Transaction PIN</h3>

                                                                        <input type="number" onChange={(e) => setTransactionPin(e.target.value)} className='confirmMainContainer_Sub_22_Confirm_Form' />
                                                                    
                                                                       

                                                                </center>

                                                                     {/* save beneficiary */}
                                                                 <div className="saveBenficiaryContainer">
                                                                    <div className="saveBenficiaryContainer_1">
                                                                        <p>Save as Beneficiary</p>
                                                                    </div>

                                                                    <div className="saveBenficiaryContainer_2">
                                                                        <div class="form-check form-switch formswitchCss">
                                                                            <input class="form-check-input checkBeneficiaryButton" type="checkbox" role="switch" id="flexSwitchCheckDefault"   />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <br />
                                                                <button className="depositProceedButton" id="depositProceedButton" onClick={confirmRequest}><i class="bi bi-send-check"></i> Confirm Transfer </button>
                                                                {/* <button className="depositProceedButton" id="depositProceedButton" onClick={confirmRequest}><i class="bi bi-send-check"></i> Proceed </button> */}
                                                                    <button id="processingButton" className="cmn-btn w-100 buttForm"  disabled> 
                                                                        <div class="spinner-border" role="status" style={{ width: 18, height:18 }}>
                                                                            <span class="visually-hidden" >Loading...</span>
                                                                        </div>
                                                                        PROCESSING...
                                                                    </button>

                                                            </div>
                                                            
                                                        
                                                        </div>
                                                        </form>


                                                       
                                                       

                                                       


                                                       


                                                    </div>
                                                 







                                                <br /> 
                                                
                                            

                                                {/* submit button */}

                                                
                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>
























                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={withimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}