// TradeFinance












import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function TradeFinance ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                   <li> <Link to='/businessbanking'> Business Banking </Link> </li>
                                   <li> <Link to='/businessaccount'> Business Account </Link> </li>
                                    {/* <li> <Link to='/tradefinance'> Trade Finance </Link> </li> */}
                                   
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Trade Finance </h2>

            
                                <p style={{ color: "#333", }}>
                                Enhance your trading status and achieve your domestic and international trade objectives with our Trade Finance solutions. You will have access to an extensive range of trade and finance services along with the expertise of our trade specialists, underpinned by our global network across various markets.


                                </p>
                                <p style={{ color: "#333", }}>
                                We provide a number of services to support your business activities anywhere in the world. We were awarded Confirming Bank status by the International Finance Corporation as part of their Global Trade Finance Programme, thereby strengthening our trade finance capabilities further.


                                </p>


                                <h2> We offer an extensive number of Trade Finance services including: </h2>
                                <p style={{ color: "#333", }}>
                                    1. <b> Export Letters of Credit : </b> our good, solid reputation helps us to advise and confirm to beneficiaries anywhere in the world.


                                </p>
                                <p style={{ color: "#333", }}>
                                    2. <b> Import Letters of Credit : </b>  we can issue stand-alone letters of credit in favour of your suppliers.


                                </p>
                                <p style={{ color: "#333", }}>
                                    3. <b> Standby Letters of Credit : </b>  to support open-account trading and to mitigate payment risk.


                                </p>
                                <p style={{ color: "#333", }}>
                                    4. <b> Payment Guarantee : </b>  providing financial security to the beneficiary if a buyer fails to pay.


                                </p>
                                <p style={{ color: "#333", }}>
                                    5. <b> Risk Participation : </b>  improving management of foreign trade exposure.


                                </p>


                                <h2> Why choose our Competitive Trade Finance services and products? </h2>
                                <p style={{ color: "#333", }}>
                                    1. We have extensive knowledge of the market and an excellent relationship with correspondent banks and global trade partners.

                                </p>
                                <p style={{ color: "#333", }}>
                                    2. You will have easy access to our dedicated and experienced team of Trade Finance experts who are on hand to provide technical support and guidance.


                                </p>
                                <p style={{ color: "#333", }}>
                                    3. We will enhance your trade relationships with new and existing suppliers.

                                </p>
                                <p style={{ color: "#333", }}>
                                    4. We will assist you with expanding your business and strengthening your business relationships.

                                </p>
                                <p style={{ color: "#333", }}>
                                    5. You will be provided with excellent customer service on a daily basis.

                                </p>
                                <p style={{ color: "#333", }}>
                                    6. We will process your trade transactions in a timely and efficient manner.

                                </p>
                                

                                

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}