// TransferSuccessful









import $ from "jquery";
import {Link } from "react-router-dom";

// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";

import {useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

// import dash images
import withimg from "../DashImg/withimg.svg";

export default function TransferSuccessful(){
    const navigateRoutes = useNavigate();
    

    const [sucMsg, setSucMsg] = useState('');

    useEffect((e) => {
        const transferType = Cookies.get('transferType');
        
        if(transferType){

            if(transferType == "International"){
                let theMsgBe = "The recipient account is expected to be credited within 2 - 5 business working days, subject to notification by the bank.";
                setSucMsg(theMsgBe);

            }else if(transferType == "Local"){

                let theMsgBe = "The recipient account is expected to be credited within immediately, subject to notification by the bank.";
                setSucMsg(theMsgBe);

            }else{


            }




        }else{
            navigateRoutes('/login', { replace: true });
        }

    }, []);




    const clearTransferCookieLogs = (e) => {
        e.preventDefault();


        Cookies.remove("receiverAcctName");
        Cookies.remove("receiverAcctNumber");
        Cookies.remove("receiverBankName");
        Cookies.remove("receiverRoutingNumber");
        Cookies.remove("receiverSwiftcode");
        Cookies.remove("sendingAmount");
        Cookies.remove("sendingPurpose");
        Cookies.remove("transferType");
        Cookies.remove("currenttransactionID");
        Cookies.remove("transferDate");

        navigateRoutes('/dashboard');

        
       
    }


    const goToViewReceiptPage = (e) => {
        e.preventDefault();

        navigateRoutes('/userviewtransactionreceipt');
    
       
    }



    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                       



                                        {/* box 2 */}

                                                                                
                                        <div className="depositMainContainer">
                                            <div className="depositMainContainer_Sub" style={{ height: "auto" }}>
                                                
                                                {/* progress bar1 */}

                                            {/* <div className="progressContainerForCode">
                                                <div className="progressBar_ConfirmTransfer"></div>
                                            </div> */}

                                            <form>
                                               
                                                    <h3 className="codeLabel"> </h3>

                                                    
                                                    <div className="confirmMainContainer">
                                                        {/* <br /> */}
                                                        


                                                        <div className="confirmMainContainer_Sub" style={{ border: "none", }}>
                                                           
                                                            <div className="confirmMainContainer_Sub_22">
                                                                <center>
                                                                        <div className="iconForTransferSuccessful">

                                                                        </div>

                                                                        {/* <br /> */}
                                                                    <h3  style={{ fontSize:25, }}>Transfer  Successful</h3>

                                                                    <p className="iconForTransferSuccessful_para">
                                                                        {sucMsg}
                                                                    </p>

                                                                <br />

                                                                <div className="confirmProceedButtonContainer">
                                                                    <button className="confirmProceedButton" onClick={goToViewReceiptPage}> VIEW RECIEPT  </button> 

                                                                
                                                                    <button className="confirmProceedButton" onClick={clearTransferCookieLogs}> DONE </button>
                                                                </div>
                                                               
                                                                </center>

                                                            </div>
                                                            
                                                        
                                                        </div>


                                                       
                                                       

                                                       


                                                       


                                                    </div>
                                                 







                                                <br /> 
                                                
                                            

                                                {/* submit button */}

                                                
                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>
























                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={withimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}