
import {Link } from "react-router-dom";


import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';



export default function Login ()
{
    return (
            <> 
            
                <Head />

                <div className='floatingCryptocoins'>
                </div>


                    <section>
                        <div className='userLoginContainer'>

                                <div className='userLoginContainerSub'>

                                <div className='userLoginContainerSubCard1'>
                                    {/* <img src="bg/login.svg" alt="Login Image" width={400} height={400} /> */}
                                </div>

                                <div className='userLoginContainerSubCard2'>

                                        <div className='userLoginContainerSubCard2Form'>


                                                     <div className="row justify-content-center">
                                                        <div className="col-lg-12">
                                                        <form action="#" method="post" role="form" className="php ">
                                                        
                                                            <h3>Reset Your Password</h3>
                                                            
                                                            <div className="form-group mt-3">
                                                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Username"
                                                                    required />
                                                            </div>
                                                            <br />

                                                            <div className="form-group mt-3">
                                                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter New Password"
                                                                    required />
                                                            </div>

                                                            <div className='loginFormSubContainer'>
                                                            
                                                                    {/* <div className='noAcct'>

                                                                        <a href=''>
                                                                            Don't have an account? Sign Up Now.
                                                                        </a>
                                                                    </div> */}


                                                                <div className='forgetPwd'>
                                                                <Link to='/login' style={{ color: " #333" }}>
                                                                        Login Your Account
                                                                    </Link>
                                                                </div>


                                                                <p className="clearx"></p>
                                                            </div>

                                                           
                                                           
                                                           
                                            
                                                            <div className="mt-3">
                                                                <button className="cmn-btn w-100 buttForm" type="submit">RESET PASSWORD</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                        </div>
                                </div>





                                <p className="clearx"></p>
                                </div>

                           
                        </div>

                    </section>














                
                <Foot />
            
            
            
            
             </>

    );


}