// DepositViaCrypto




import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";




import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


// import dash images
import depimg from "../DashImg/depimg.svg";


export default function DepositViaCrypto(){

    const navigateRoutes = useNavigate();
    const [errMsg, setErrMsg] = useState("");

    const [depositMethod, setDepositMethod] = useState("");
    const [depositAmount, setDepositAmount] = useState("");
   





    const confirmRequest = (e) => {

        e.preventDefault();

        
        $("#depositProceedButton").hide();
        $("#processingButton").show();
       
        if(depositMethod == ""){
            toast.error("Select a deposit method");
            setErrMsg("Select a deposit method");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if(depositMethod == "Select"){
            toast.error("Select a deposit method");
            setErrMsg("Select a deposit method");
            $("#depositProceedButton").show();
            $("#processingButton").hide();


        }else if(depositAmount == ""){
            toast.error("Enter amount");
            setErrMsg("Enter amount");
            $("#depositProceedButton").show();
            $("#processingButton").hide();


        }else if(isNaN(depositAmount)){
            toast.error("Enter a valid amount");
            setErrMsg("Enter a valid amount");
            $("#depositProceedButton").show();
            $("#processingButton").hide();


        }else{
            setErrMsg("");

            // starts here 
            const checkData1 = localStorage.getItem('tokenID');
            const checkData2 = localStorage.getItem('currentUserName');

            if(checkData1 && checkData2){
                let urls = "http://127.0.0.1:8000/api/proceedtodepositrequest";

                async function proceedToSendDataToServer (){
                    try{
                        const registerPostFeedBack = await axios.post(urls,{

                            tokenCode: checkData1,
                            userName: checkData2,

                            depositMethod: depositMethod,
                            depositAmount: depositAmount,



                        }).then((res) => {

                            let feedbackMsg = res.data.msg;
                            let feedbackStatusCode = res.data.status;


                            if(feedbackStatusCode == 200){
                                toast.success(feedbackMsg);
                                
                                Cookies.set('depositMethod', depositMethod, { expires: 1 });
                                Cookies.set('depositAmount', depositAmount, { expires: 1 });
                                
                                setTimeout(() => {
                                   
                                    navigateRoutes('/dashpaymentpage');
                                  }, 1000);


                                // $("#depositProceedButton").show();
                                // $("#processingButton").hide();
    
                              
                            }
           
                            if(feedbackStatusCode == 402){
                                toast.success(feedbackMsg);
                                setErrMsg(feedbackMsg);
                                $("#setErrMsg").show();
                                $("#depositProceedButton").show();
                                $("#processingButton").hide();
                                
                            }
    
                            if(feedbackStatusCode == 401){
                                navigateRoutes('/login', { replace: true });
                                toast.success(feedbackMsg);
                            }
    
                            if(feedbackStatusCode == 501){
                                navigateRoutes('/login', { replace: true });
                                toast.success(feedbackMsg);
                            }
       
                            

                        });

                   }catch (err){
 
                    toast.error("Error connecting to the server.");
                    setErrMsg("Error connecting to the server.");
                    $("#depositProceedButton").show();
                    $("#processingButton").hide();
             
                    //  console.log(err);
 
                 }


                }
                proceedToSendDataToServer();


            }else{

            navigateRoutes('/login', { replace: true });
           }








        }


    
    
    
    
    }






    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content" >

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                    {/* box 1 */}
                                    


                                    <ToastContainer />  

                                        {/* box 2 */}

                                        
                                        <div className="depositMainContainer">
                                            <div className="depositMainContainer_Sub" style={{ 
                                               height: "auto",
                                            }}>

                                            <form>
                                               

                                                    <p style={{ color: "red" }}>
                                                        {errMsg}
                                                    </p>


                                                {/* select payment method */}
                                                <label htmlFor="fullname" className='depositFormLabel'>Select deposit method. </label> <br />
                                                <select className='DepositformInputSelect' style={{height:40}} onChange={(e) => setDepositMethod(e.target.value)}>
                                                    {/* <option value="select">-- Select --</option> */}
                                                    <option value="Select"> -- Select -- </option>
                                                    <option value="Bitcoin">Deposit Via Bitcoin </option>
                                                    <option value="USDT(Trc20)">Deposit Via USDT(Trc20) </option>
                                                    <option value="Ethereum">Deposit Via Ethereum </option>
                                                    
                                                </select>

                                                <br /> 
                                                <br /> 

                                                {/* enter amount */}
                                                <label htmlFor="fullname" className='depositFormLabel'>Enter amount you wish to deposit. </label> <br />
                                                
                                                    <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div>
                                                        <div className="depositAmountContainer_2">
                                                        <input type="number" placeholder="100" onChange={(e) => setDepositAmount(e.target.value)}  className='DepositformInputAmount' />
                                                        </div>
                                                    </div>


                                                    <br /> 
                                                    {/* <br />  */}


                                                {/* submit button */}

                                                <button className="depositProceedButton" id="depositProceedButton" onClick={confirmRequest}>Deposit Funds <i class="bi bi-cash"></i> </button>

                                                <button id="processingButton" className="cmn-btn w-100 buttForm"  disabled> 
                                                    <div class="spinner-border" role="status" style={{ width: 18, height:18 }}>
                                                        <span class="visually-hidden" >Loading...</span>
                                                    </div>
                                                    PROCESSING...
                                                </button>
                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>


















                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                    <img src={depimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}