// AccountSetUp3












import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";

import {useState} from 'react';
import axios from 'axios';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';

// import dash images
import dashimg1 from "../DashImg/dashimg1.svg";

import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";


export default function AccountSetUp3(){

    const navigateRoutes = useNavigate();
    const [errMsg, setErrMsg] = useState("");


    const [houseAddr, sethouseAddr] = useState("");
    const [acctTypesx, setacctTypesx] = useState("");
    const [transactPin, settransactPin] = useState("");
    

    const [msgLabel, setMsgLabel] = useState("");
    const [msgPara, setMsgPara] = useState("");



    const hideModalContainerMsg = () => {
        $("#showModalContainerMsg").hide();


        navigateRoutes('/accountSetUpDeposit');

        // $("#depositProceedButton").show();
        // $("#processingButton").hide();
    }



    const goToPage = (e) => {
        e.preventDefault();


        if(acctTypesx == "" || acctTypesx == "Select"){

            toast.error("Select account type");
            setErrMsg("Select account type");
            return false;

        

        }else if(houseAddr == ""){

            toast.error("Enter house address");
            setErrMsg("Enter house address");
            return false;

        }else if(transactPin == ""){

            toast.error("Enter your 4 digit transaction pin");
            setErrMsg("Enter your 4 digit transaction pin");
            return false;

        }else if(isNaN(transactPin)){

            toast.error("Enter a valid 4 digit transaction pin");
            setErrMsg("Enter a valid 4 digit transaction pin");

            return false;

        }else if(transactPin == ""){

            toast.error("Enter your 4 digit transaction pin");
            setErrMsg("Enter your 4 digit transaction pin");
            return false;

        }else if(transactPin.length < 4 || transactPin.length > 4){

            toast.error("Enter a minimum and maximum of 4 digit pin");
            setErrMsg("Enter a minimum and maximum of 4 digit pin");
            return false;


        }else{
            setErrMsg("");
            $("#depositProceedButton").hide();
            $("#processingButton").show();


            // get other saved data
            const country = Cookies.get('country');
            const states = Cookies.get('states');
            const city = Cookies.get('city');
            const phone = Cookies.get('phone');
            const maritalStatus = Cookies.get('maritalStatus');
            const gender = Cookies.get('gender');
            const occupation = Cookies.get('occupation');
            const dob = Cookies.get('dob');




// starts here 
const checkData1 = localStorage.getItem('tokenID');
const checkData2 = localStorage.getItem('currentUserName');

if(checkData1 && checkData2){
    let urls = "http://127.0.0.1:8000/api/setupaccountpostapi";

    async function proceedToSendDataToServer (){
        try{
            const registerPostFeedBack = await axios.post(urls,{

                tokenCode: checkData1,
                userName: checkData2,

                houseAddr: houseAddr,
                acctTypesx: acctTypesx,
                transactPin: transactPin,
                country: country,
                states: states,
                city: city,
                phone: phone,
                maritalStatus: maritalStatus,
                gender: gender,
                occupation: occupation,
                dob: dob,



            }).then((res) => {

                let feedbackMsg = res.data.msg;
                let feedbackStatusCode = res.data.status;


                if(feedbackStatusCode == 200){
                    toast.success(feedbackMsg);
                    

                    Cookies.remove("houseAddr");
                    Cookies.remove("acctTypesx");
                    Cookies.remove("transactPin");
                    Cookies.remove("country");
                    Cookies.remove("states");
                    Cookies.remove("city");
                    Cookies.remove("phone");
                    Cookies.remove("maritalStatus");
                    Cookies.remove("gender");
                    Cookies.remove("occupation");
                    Cookies.remove("dob");
                    


                    
                            setMsgLabel("Account Setup Successful");
                                setMsgPara("Your banking account has been setup successfully, you need to activate your banking account with a deposit of $400, click on the button below to proceed to make your deposit.");
                                setTimeout(() => {
                                    $("#showModalContainerMsg").show();
                                  }, 1000);
                              

                  
                }

                if(feedbackStatusCode == 402){
                    setErrMsg(feedbackMsg);
                    $("#errMsg").show();
                    $("#depositProceedButton").show();
                    $("#processingButton").hide();
                }

                if(feedbackStatusCode == 401){
                    navigateRoutes('/login', { replace: true });
                }

                if(feedbackStatusCode == 501){
                    navigateRoutes('/login', { replace: true });
                }

                

            });

       }catch (err){

        toast.error("Error connecting to the server.");
        setErrMsg("Error connecting to the server.");
        $("#depositProceedButton").show();
        $("#processingButton").hide();
 
        //  console.log(err);

     }


    }
    proceedToSendDataToServer();


}else{

navigateRoutes('/login', { replace: true });
}















        }








       
    }


    





    return (
        <>
            <div className="mainDashIndexContainer" >

                {/* import dashnav here */}

                   
                   


             {/* main section */}
             <section className="mainContainerSection" >


                {/* import dash nav here */}
                   






                

            {/* main */}

                <main className="mainDashContent" >



                    {/* start container holding main content */}
                   <div className="mainDashContent_Sub" >

                        
                        {/* start other content enters here */}
                        <div className="mainDashContent_Sub_Content" >

                                        {/* dash view content */}
                                <div className="mainContent_1" >

                                    <div className="mainContent_1_Container" >
                                        


                                    <ToastContainer /> 





                                        {/* box 1 */}
                                        {/* <div className="mainContent_1_Container_box_1" style={{ 
                                            margin:0,
                                            position: "absolute",
                                            top: "40%",
                                         }}>
                                            <div className="mainContent_1_Container_box_1_Content">
                                               
                                                <h2>   Welcome To Arbitrage Credit Union </h2>
                                                <small style={{ color: "#fff",  }}>
                                                    Hold while we redirect you to your banking dashboard.
                                                </small>

                                                    <br />
                                                    <br />
                                                <div class="spinner-border text-light" role="status">
                                                    <span class="sr-only">  </span>
                                                </div>

                                            </div>

                                        </div> */}

                                        <div className="depositMainContainer" style={{ 
                                            margin:0,
                                            position: "absolute",
                                            top: "0%",
                                            
                                         }}>
                                           
                                            <div className="depositMainContainer_Sub" style={{ height: "auto" }}>
                                                
                                                

                                                {/* progress bar1 */}
                                            <div className="progressContainerForCode">
                                                <div className="progressBar_34" style={{ 
                                                    width: "95%",
                                                    height: 7,
                                                    backgroundColor: "green",
                                                 }}></div>
                                            </div>
                                           
                                            <form>
                                               
                                                    <h3 className="codeLabel"> Account Setup (3/3)  </h3>
                                                    <br />

                                                    <p style={{ color: "red" }}>
                                                        {errMsg}
                                                    </p>




                                                    <label htmlFor="fullname" className='depositFormLabel'>Enter House Address </label> <br />   
                                                    <div className="depositAmountContainer">
                                                        
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Enter House Address" onChange={(e) => sethouseAddr(e.target.value)}   className='DepositformInputAmount' />
                                                        
                                                        </div>
                                                    </div>
                                                    <br />

                                                 <label htmlFor="fullname" className='depositFormLabel'>Select Account Type </label> <br />   
                                                    <div className="depositAmountContainer">
                                                        
                                                    <select className='DepositformInputSelect' style={{height:40}} onChange={(e) => setacctTypesx(e.target.value)}>
                                                        <option value="select">-- Select --</option>

                                                            <option value="Savings">Savings</option>
                                                            <option value="Current">Current</option>
                                                            <option value="Checking">Checking</option>
                                                            <option value="Fixed Deposit">Fixed Deposit</option>
                                                            <option value="NON-Resident">NON-Resident</option>
                                                            <option value="Online Banking">Online Banking</option>
                                                            <option value="Joint Account">Joint Account</option>
                                                            <option value="DOMICILIARY ACCOUNT">DOMICILIARY ACCOUNT</option>
                                                </select>
                                                    </div>
                                                    <br />


                                                   
                                                    
                                                 <label htmlFor="fullname" className='depositFormLabel'>Enter your 4 Digit Transaction Pin </label> <br />   
                                                    <div className="depositAmountContainer">
                                                        
                                                        <div className="depositAmountContainer_2">
                                                        <input type="number" placeholder="Enter your 4 Digit Transaction Pin" onChange={(e) => settransactPin(e.target.value)}   className='DepositformInputAmount' />
                                                        
                                                        </div>
                                                    </div>
                                                   
                                                 
                                                
                                                
                        
                                                 <br />
                                                {/* submit button */}

                                                {/* <button className="depositProceedButton"><i class="bi bi-send-check"></i> Proceed </button> */}
                                                <button className="depositProceedButton" id="depositProceedButton" onClick={goToPage}> Next {">>"} </button>
                                                <button id="processingButton" className="cmn-btn w-100 buttForm"  disabled> 
                                                    <div class="spinner-border" role="status" style={{ width: 18, height:18 }}>
                                                        <span class="visually-hidden" >Loading...</span>
                                                    </div>
                                                    PROCESSING...
                                                </button>
                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>
                                        



                                      



                                    </div>

                                
                                </div>




                                   




                               




                        </div>
                     {/* end other content enters here */}




                    </div>                             
                     {/* end container holding main content */}





  {/* start overlay modal */}
  <div className="modalOverlay" id="showModalContainerMsg">
                                                        <div className="modalOverlayContainer" style={{  height: "auto", padding:40, }}>

                                            <center>
                                                {/* succImg */}
                                                <img src={suc1} width='60' style={{paddingTop: 30}} />
                                                {/* <img src={errimg} width='70' style={{paddingTop: 30}} /> */}

                                                <h3> {msgLabel} </h3>

                                                {/* <h3 style={{color: "red"}}>Error </h3> */}
                                                
                                                <div className="modalPara">
                                                    <p>
                                                        {msgPara}
                                                    </p>

                                                  

                                                </div>
                                               


                                                <div className="modalButtons" style={{ height:"auto", padding:8, width: "100%" }}> 

                                                    {/* <div className="confirmModalButton">
                                                        <p>
                                                            Confirm
                                                        </p>
                                                    </div> */}

                                                    {/* cancelModalButton */}
                                                    <div  className="confirmModalButton" onClick={hideModalContainerMsg}>
                                                        <p>
                                                        PROCEED TO DEPOSIT
                                                        </p>
                                                    </div>

                                                </div>

                                            </center>
                                        

                                        </div>
                                    </div>
                                    {/* end overlay modal */}












                     {/* start footer is starts here */}
                        {/* <DashFoot /> */}
                    {/* end footer is starts here */}                            

                </main>




            












   
               
                
               
          
   

            </section>





               










              













            </div>
    
        </>
    );



}

