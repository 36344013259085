// DashTransaction

import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";



// import dash images
import dashimg1 from "../DashImg/dashimg1.svg";

import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";


export default function DashTransaction(){

 





    return (
        <>
            <div className="mainDashIndexContainer">

                {/* import dashnav here */}

                    <DashNav />


             {/* main section */}
             <section className="mainContainerSection">


                {/* import dash nav here */}
                    <DashHead />






                

            {/* main */}

                <main className="mainDashContent">



                    {/* start container holding main content */}
                   <div className="mainDashContent_Sub">

                        
                        {/* start other content enters here */}
                        <div className="mainDashContent_Sub_Content">

                                        {/* dash view content */}
                                <div className="mainContent_1">

                                    <div className="mainContent_1_Container">
                                        
                                        {/* box 1 */}
                                       



                                        {/* box 2 */}
                                        <div className="mainContent_1_Container_box_2">
                                             <div className="mainContent_1_Container_box_2_Content">
                                                <h3>All Transaction Histories</h3>


                                                {/* recent  */}
                                                <div className="mainContent_1_Container_box_2_Content_recentContainer">

                                                     {/* begins loops here */}
                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Deposit</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500.</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Withdrawal</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500.</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Referral Bonus</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>

                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Investment</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500.</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>
                                                        <div className="notificationDetailsCard_1_Card_Details notifyHover">
                                                            <h4 className="notificationDetailsCard_1_Card_Details_label">Investment</h4>
                                                            <p className="notificationDetailsCard_1_Card_Details_para">You deposited $500.</p>
                                                            <small className="notificationDetailsCard_1_Card_Details_date">23 Dec, 2024.</small>
                                                        </div>
                                                        {/* end loops here */}

                                                        


                                                        <div className="notificationFooter">
                                                            {/* <Link to="/dashtransaction">
                                                                 <p>view all transactions </p>
                                                           </Link> */}
                                                           
                                                        </div>
                                                </div>

                                             </div>

                                        </div>




                                    </div>

                                
                                </div>




                                    {/* start overlay modal */}
                                    <div className="modalOverlay">
                                        <div className="modalOverlayContainer">

                                            <center>
                                                {/* succImg */}
                                                <img src={suc1} width='60' style={{paddingTop: 30}} />
                                                {/* <img src={errimg} width='70' style={{paddingTop: 30}} /> */}

                                                <h3>Successful</h3>

                                                {/* <h3 style={{color: "red"}}>Error </h3> */}
                                                
                                                <div className="modalPara">
                                                    <p>
                                                        Your transaction of $100 was successful, please proceed to confirm.
                                                       
                                                    </p>

                                                </div>
                                               


                                                <div className="modalButtons"> 

                                                    <div className="confirmModalButton">
                                                        <p>
                                                            Confirm
                                                        </p>
                                                    </div>
                                                    <div className="cancelModalButton">
                                                        <p>
                                                            Cancel
                                                        </p>
                                                    </div>

                                                </div>

                                            </center>
                                        

                                        </div>
                                    </div>
                                    {/* end overlay modal */}





                                {/* image container */}
                                <div className="mainContent_2">
                                    <div className="mainContent_2_Container">
                                    <img src={dashimg1} style={{width: "100%", height: "50vh" }}  />
                                    </div>
                                </div>





                        </div>
                     {/* end other content enters here */}




                    </div>                             
                     {/* end container holding main content */}











                     {/* start footer is starts here */}
                        {/* <DashFoot /> */}
                    {/* end footer is starts here */}                            

                </main>




            












   
               
                
               
          
   

            </section>





               










              













            </div>
    
        </>
    );



}

