// ApplyLoan



import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";





import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';



// import dash images
import withimg from "../DashImg/withimg.svg";

export default function ApplyLoan(){

    const navigateRoutes = useNavigate();
    const [errMsg, setErrMsg] = useState("");


    const [loantype, setloantype] = useState("");
    const [howSoon, sethowSoon] = useState("");
    const [loanAmount, setloanAmount] = useState("");
    const [loanPurpose, setloanPurpose] = useState("");

    const [msgLabel, setMsgLabel] = useState("");
    const [msgPara, setMsgPara] = useState("");






    const hideModalContainerMsg = () => {
        $("#showModalContainerMsg").hide();

        navigateRoutes('/dashboard');
        
        // $("#depositProceedButton").show();
        // $("#processingButton").hide();
    }


    const confirmRequest = (e) => {

        e.preventDefault();

        $("#depositProceedButton").hide();
        $("#processingButton").show();

    
        if(loantype == "" || loantype == "Select"){
            toast.error("Select a loan type");
            setErrMsg("Select a loan type");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;


        }else if(howSoon == "" || howSoon == "Select"){
            toast.error("Select how soon do you need the card");
            setErrMsg("Select how soon do you need the card");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;
    

        }else if(loanAmount == ""){
            toast.error("Enter  amount");
            setErrMsg("Enter  amount");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;

        }else if(isNaN(loanAmount)){
            toast.error("Enter a valid amount");
            setErrMsg("Enter a valid amount");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
    

        }else if(loanPurpose == ""){
            toast.error("Enter  purpose of the loan");
            setErrMsg("Enter  purpose of the loan");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;
    

        }else{


            setErrMsg("");

            // starts here 
            const checkData1 = localStorage.getItem('tokenID');
            const checkData2 = localStorage.getItem('currentUserName');
            
            if(checkData1 && checkData2){
                let urls = "http://127.0.0.1:8000/api/loanrequestpostapi";
            
                async function proceedToSendDataToServer (){
                    try{
                        const registerPostFeedBack = await axios.post(urls,{
            
                            tokenCode: checkData1,
                            userName: checkData2,

                            loantype: loantype,
                            howSoon: howSoon,
                            loanAmount: loanAmount,
                            loanPurpose: loanPurpose,
            
            
            
                        }).then((res) => {
            
                            let feedbackMsg = res.data.msg;
                            let feedbackStatusCode = res.data.status;
            
            
                            if(feedbackStatusCode == 200){
                                toast.success(feedbackMsg);
                                
                                setMsgLabel("Loan Request");
                                setMsgPara("Loan request was successful, a mail will be sent to you once your loan is approved.");
                                setTimeout(() => {
                                    $("#showModalContainerMsg").show();
                                  }, 1000);
                              
                            }

            
                            if(feedbackStatusCode == 402){
                                setErrMsg(feedbackMsg);
                                $("#errMsg").show();
                                $("#depositProceedButton").show();
                                $("#processingButton").hide();
                            }
            
                            if(feedbackStatusCode == 401){
                                navigateRoutes('/login', { replace: true });
                            }
            
                            if(feedbackStatusCode == 501){
                                navigateRoutes('/login', { replace: true });
                            }
            
                            
            
                        });
            
                   }catch (err){
            
                    toast.error("Error connecting to the servers.");
                    setErrMsg("Error connecting to the servers.");
                    $("#depositProceedButton").show();
                    $("#processingButton").hide();
             
                    //  console.log(err);
            
                 }
            
            
                }
                proceedToSendDataToServer();
            
            
            }else{
            
            navigateRoutes('/login', { replace: true });
            }
            
            







        }


    
    
    
    
    
    
    }














    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                <ToastContainer />  
 



                                        {/* box 2 */}

                                                                                
                                        <div className="depositMainContainer">
                                            <div className="depositMainContainer_Sub" style={{ 
                                                height: "auto",
                                             }}>

                                            <form>
                                               

                                                <h3 className="intLabel"> Apply For Loan </h3>

                                                <p style={{ color:"red" }}>
                                                    {errMsg}
                                                </p>

                                                <label htmlFor="fullname" className='depositFormLabel'> Select loan type </label> <br />
                                                <select className='DepositformInputSelect' style={{height:40}} onChange={(e) => setloantype(e.target.value)}>
                                                    {/* <option value="select">-- Select --</option> */}
                                                    <option value="Select"> -- Select -- </option>
                                                    <option value="Starter Loan"> Starter Loan ($500 - $5,000)  </option>
                                                    <option value="Standard Loan"> Standard Loan ($6,000 - $20,000) </option>
                                                    <option value="Executive Loan"> Executive Loan ($21,000 - $50,000) </option>
                                                    <option value="Business Loan"> Business Loan ($51,000 - unlimited) </option>
                                                    
                                                </select>


                                                <br /> 
                                                <br /> 


                                                <label htmlFor="fullname" className='depositFormLabel'>How soon do you need the loan? </label> <br />
                                                <div className="depositAmountContainer">
                                                    <select className='DepositformInputSelect' style={{height:40}} onChange={(e) => sethowSoon(e.target.value)}>
                                                        <option value="Select"> -- Select -- </option>
                                                        <option value="Immediately">Immediately </option>
                                                        <option value="Within two weeks">Within two weeks </option>
                                                        <option value="Within a month">Within a month </option>
                                                        <option value="Not sure yet">Not sure yet </option>
                                                        
                                                    </select>
                                                </div>
                                                <br /> 
                                               
                                               
                                                
                                               
                                                 
        

                                                {/* enter amount */}
                                                <label htmlFor="fullname" className='depositFormLabel'>Enter amount  </label> <br />
                                                
                                                    <div className="depositAmountContainer">
                                                        <div className="depositAmountContainer_1">
                                                            <span>$</span>
                                                        </div>
                                                        <div className="depositAmountContainer_2">
                                                        <input type="number" placeholder="100"   className='DepositformInputAmount' onChange={(e) => setloanAmount(e.target.value)} />
                                                        </div>
                                                    </div>


                                                    <br /> 
                                                   


                                                    <label htmlFor="fullname" className='depositFormLabel'>Enter purpose of loan </label> <br />
                                                
                                                <div className="depositAmountContainer">
                                                    {/* <div className="depositAmountContainer_1">
                                                        <span>$</span>
                                                    </div> */}
                                                    <div className="depositAmountContainer_2">
                                                    <input type="text" placeholder="Purpose of loan"   className='DepositformInputAmount' onChange={(e) => setloanPurpose(e.target.value)} />
                                                    </div>
                                                </div>


                                            <br /> 
    


                                                {/* submit button */}

                                                {/* <button className="depositProceedButton"> <i class="bi bi-send-check"></i> Apply  </button> */}
                                                <button className="depositProceedButton" id="depositProceedButton" onClick={confirmRequest}>APPLY NOW <i class="bi bi-cash"></i> </button>

                                                <button id="processingButton" className="cmn-btn w-100 buttForm"  disabled> 
                                                    <div class="spinner-border" role="status" style={{ width: 18, height:18 }}>
                                                        <span class="visually-hidden" >Loading...</span>
                                                    </div>
                                                    PROCESSING...
                                                </button>
                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>
























                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={withimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}







                                            {/* start overlay modal */}
                                            <div className="modalOverlay" id="showModalContainerMsg">
                                                            <div className="modalOverlayContainer" style={{  height: "auto", padding:40, }}>

                                            <center>
                                                {/* succImg */}
                                                <img src={suc1} width='60' style={{paddingTop: 30}} />
                                                {/* <img src={errimg} width='70' style={{paddingTop: 30}} /> */}

                                                <h3> {msgLabel} </h3>

                                                {/* <h3 style={{color: "red"}}>Error </h3> */}
                                                
                                                <div className="modalPara">
                                                    <p>
                                                        {msgPara}
                                                    </p>

                                                  

                                                </div>
                                               


                                                <div className="modalButtons"> 

                                                    {/* <div className="confirmModalButton">
                                                        <p>
                                                            Confirm
                                                        </p>
                                                    </div> */}

                                                    {/* cancelModalButton */}
                                                    <div className="confirmModalButton" onClick={hideModalContainerMsg}>
                                                        <p>
                                                            OK
                                                        </p>
                                                    </div>

                                                </div>

                                            </center>
                                        

                                        </div>
                                    </div>
                                    {/* end overlay modal */}








                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}